.page-cms {
  .page-content {
    div:not(.elementor-*) {
      min-height: 259px;
      padding: 0;

      p {
        font-size: 13px;
        line-height: 23px;
      }

      h2 {
        font-family: $headingFontFamily;
      }

      span {
        color: $cms-text;
      }
    }

    .flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      li {
        width: 200px;
        margin: 15px auto;

        img {
          width: inherit;
        }
      }
    }

    .page-heading {
      font-size: 20px;
      font-weight: 500;
      color: #222222;
    }

    .about-us {
      margin-bottom: 75px;

      img {
        width: 100%;
        height: auto;
      }

      img.auto {
        width: inherit;
      }

      h1.page-heading {
        text-align: center;
        font-size: $font-size-h2;
        line-height: 25px;
        text-transform: uppercase;
        margin: 73px 0 50px;
        font-family: $headingFontFamily;
        font-weight: 500;
      }

      h2 {
        font-size: $font-size-h2;
      }

      .bottom-border {
        padding-bottom: 84px;
        border-bottom: 1px solid $cms-border;
        margin-bottom: 90px;
      }

      .partners {
        margin-bottom: 75px;
      }

      p {
        color: $cms-text;
        margin-bottom: 28px;
      }
    }

    .brands {
      .head-logo {
        margin: 99px 0 54px;

        img {
          min-width: 249px;
        }
      }

      h1.page-heading {
        margin-top: 0;
        margin-bottom: 35px;
      }

      .mission {
        margin-bottom: 90px;
      }
    }

    .team-member {
      text-align: center;
      padding-top: 20px;
      padding-bottom: 70px;

      img {
        max-width: 100%;
        margin-bottom: 20px;
      }

      .team-name {
        font-size: 16px;
        font-weight: 700;
        color: $crd-secondary-color;
        text-transform: uppercase;
        line-height: 24px;
      }

      .team-function {
        font-size: 15px;
        color: #626367;
        line-height: 28px;
      }

      .team-contact {
        color: $crd-primary-color;
        font-size: 13px;
        line-height: 20px;
      }
    }

    .center {
      text-align: center;
    }

    .impressum {
      .content-part {
        margin: 35px 0;
      }

      h4 {
        color: $prod-title;
        font-size: 14px;
        margin-bottom: 20px;
      }

      p {
        font-size: 13px;
      }

      h2 {
        margin: 47px 0 25px;
        padding-bottom: 12px;
        position: relative;
        text-transform: uppercase;
        font-family: $headingFontFamily;

        &:after {
          content: "";
          width: 50px;
          border-bottom: 1px solid $gray-light2;
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }

      .impressum-footer {
        border-top: 1px solid #e6e6e6;
        margin-top: 30px;

        p {
          font-size: 12px;
        }
      }

      span {
        color: $gray-dark;
      }
    }

  }
}

/** AGB Collapsible**/
#accordion {
  margin: 40px 0;

  .panel-heading {
    counter-increment: ordem;
    border-bottom: 1px solid #E6E6E6;
    position: relative;
    padding-left: 20px;

    .panel-title {
      margin-bottom: 0;
      padding-bottom: 5px;
    }

    a {
      font-size: 14px;
      color: #222;
      font-weight: 700;
      line-height: 40px;
      display: inline-block;

      &:before {
        content: "\f078";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-9px);
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: 10px;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }

      &[aria-expanded="true"] {
        &:before {
          content: "\f077";
        }
      }
    }
  }

  .body-padding {
    padding-left: 20px;
    padding-top: 20px;

    a {
      &:focus {
        text-decoration: none;
        outline: none;
      }
    }
  }

  .ordered-list {
    counter-reset: foo;
    padding-left: 20px;
    padding-top: 20px;
  }

  .ordered-list li {
    list-style-type: none;
    display: table;
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 20px;
    color: #878787;
  }

  .ordered-list li:before {
    counter-increment: foo;
    content: counter(ordem) "." counter(foo) " ";
    display: table-cell;
    padding-right: 0.6em;
  }
}

/** AGB Collapsible**/


.firma-middle {
  img {
    height: 360px;
  }
}

@include media-breakpoint-up(xl) {
  span.block-desktop {
    display: block;
  }
}

@include media-breakpoint-down(md) {
  .page-content.page-cms {
    .impressum {
      h2 {
        text-align: left;
      }
    }

    .brands {
      .mission {
        margin-bottom: 30px;
      }

      .head-logo {
        margin: 54px 0;

        img {
          min-width: 0;
        }
      }
    }

    .about-us {
      margin-bottom: 20px;
    }
  }
}

@include media-breakpoint-down(sm) {
  .block-mobile {
    display: block;
  }
  #accordion .panel-heading a {
    line-height: 20px;
    padding: 12px 0 7px;

    &:before {
      top: 27px;
    }
  }
}