#cms {
  #footer {
    padding-top: 0;
  }

  #mmenu.hidden-desktop {
    z-index: 100;
  }
}

#cms, .page-content.page-cms {
  background: #E5E5E5;
}

.page-content.page-cms {
  padding: 0;
}

.p-t-20 {
  padding-top: 20px;
}

.page-cms.page-content {
  .flex.styled-list {
    list-style: none;
    padding: 0;

    li {
      width: 50%;
      margin: 0;
      border: 1px solid #D1D1D1;
      padding: 70px;
      display: flex;
      height: 240px;
      align-items: center;

      img {
        width: 50px;
        height: auto;
        display: block;
        margin-bottom: 10px;
      }
    }
  }
}

.cms-structure {
  font-size: 1.6rem;
  line-height: 1.45;
  color: #706f6e;
  overflow-x: hidden;
  text-align: left;

  .top-container {
    padding: 0px 10px 0 80px;
    text-align: left;

    .logo {
      max-width: 150px;
      display: block;

      &.wide {
        max-width: 250px;
      }
    }
  }

  img {
    max-width: 100%;
    width: 100%;
  }

  h1 {
    font-size: 64px;
    font-style: normal;
    font-weight: normal;
    line-height: 80px;
    margin-bottom: 22px;
    color: $cms-content;
  }

  .small-top-heading {
    font-size: 50px;
    line-height: 61px;
  }

  .black {
    h1, h2, h3 {
      color: black;
    }
  }

  .btn {
    font-weight: 700;
  }

  .top-section {
    background-size: cover;
    height: 800px;
    max-height: 80vh;
    position: relative;
    display: flex;
    align-items: center;

    .content {
      z-index: 2;
      position: relative;
    }

    &.auto-height {
      max-height: none;
      height: auto;
      padding: 50px 0;
    }
  }

  .section {
    padding: 80px 0;

    &.white {
      background: white;
      color: #706f6e;
    }

    &.center {
      p {
        text-align: center;
      }
    }
  }

  .section-text {
    padding: 0 20px;
  }

  .section, .top-section {
    p {
      font-size: 23px;
      font-style: normal;
      line-height: 40px;
      font-weight: normal;
      color: $cms-content;
      text-align: left;
    }

    .black {
      p {
        color: black;
      }
    }
  }

  .section-title {
    font-size: 34px;
    line-height: 45px;
    margin-bottom: 48px;
  }

  .btn-link {
    background: $brand-primary;
    border-radius: 49px;
    font-size: 16px;
    line-height: 32px;
    text-transform: uppercase;
    color: white;
    width: 220px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;

    &:hover {
      text-decoration: none;
    }

    &.wide {
      width: 300px;
    }

    &.extra-wide {
      width: 400px;
    }
  }

  .centered-text {
    text-align: center;
  }

  .light-gray {
    background: #f7f7f7;
  }

  ul {
    list-style-type: disc;
    padding-left: 22px;
  }

  .centered-list {
    text-align: left;
    margin: 0 auto;
    width: 50%;

    li {
      font-size: 23px;
      line-height: 35px;
    }
  }

  .no-style-item {
    list-style: none;
    margin-left: -22px;
  }

  .flex-center-vertical {
    align-items: center;
  }

  .m-b-0 {
    margin-bottom: 0;
  }

  /********************************* frame section *************************************/

  .framed-section {
    .frame-container {
      .section-title {
        padding-left: 20px;
      }
    }
  }

  .pdf-list {
    list-style: none;
    border-top: 1px solid #E9E9E9;
    padding: 0;

    li {
      border-bottom: 1px solid #E9E9E9;
      padding: 16px 84px;
      display: flex;
      justify-content: space-between;

      &:hover {
        background: #F6F6F6;
      }

      .fa {
        margin-right: 14px;
      }
    }
  }

  .pdf-icon {
    height: 24px;
    width: 24px;
    margin-right: 11px;
    vertical-align: bottom;
    display: inline-block;
  }

  .pdf-description {
    text-align: left;
    font-size: 18px;
    color: #2A527E;
  }

  .list-button {
    color: #2A527E;
    font-size: 16px;

    &:hover {
      text-decoration: underline;
    }
  }

  /********************************* end frame section *************************************/

  /********************************* contact section *************************************/
  .btn.big-btn {
    display: flex;
    margin: 50px 20px 20px;
    color: white;
    background: #003B5C;
    font-size: 15px;
    padding: 17px 20px;
    height: 93px;
    width: 360px;
    border-radius: 14px;
    line-height: 25px;
    justify-content: center;
    float: left;
    align-items: center;
  }

  #contact-name {
    font-weight: bold;
  }

  .section-contact {
    padding-bottom: 3.75rem;
    color: #1f3c64;

    p {
      font-size: 1.25rem;
      line-height: 1.75rem;
      font-weight: 300;
      color: #1f3c64;
      margin: 0;
      margin-bottom: 1.75rem;
    }
  }

  .contact-data {
    max-width: 1200px;
    margin: 30px auto;
    text-align: center;
    background: white;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, .05);
    border-radius: 4px;
    padding: 40px 80px;

    h3 {
      font-size: 33px;
      margin: 0 0 33px;
    }
  }

  .contact-info {
    text-align: left;

    span {
      display: block;
      font-size: 21px;
    }

    a.collapse-span {
      color: white !important;
      background: #003B5C;
      border-top-right-radius: 68px;
      border-bottom-right-radius: 68px;
      font-size: 18px;
      margin-left: -2px!important;
      padding: 12px 19px 20px 0;
      display: none;
      line-height: 52px;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .contact-info-container {
    text-align: left;
    display: flex;
    align-items: center;
  }

  #contact-portrait {
    max-width: 260px;
    height: auto;
  }

  .contact-img {
    text-align: right;
  }

  #toggle-email, #toggle-phone {
    display: none;
  }

  #toggle-email:checked ~ #toggle-email-button,
  #toggle-phone:checked ~ #toggle-phone-button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  #toggle-email:checked ~ #contact-email,
  #toggle-phone:checked ~ #contact-phone {
    display: inline-block;
    line-height: 28px;
  }

  .collapse-info {
    height: 60px;
    margin: 10px 0;
  }

  .collapse-button {
    color: white;
    background: #003B5C;
    font-size: 30px;
    height: 60px;
    border-radius: 68px;
    line-height: 50px;
    justify-content: center;
    float: left;
    align-items: center;
    border: 0;
    padding-left: 10px;
    padding-right: 10px;
    width: 130px;
    text-align: center;

    &:hover {
      color: white;
      cursor: pointer;
    }
  }

  .contact-btn {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  /********************************* end contact section *************************************/

  .technical-pdf-section {
    .frame-container {
      padding: 4%;
      border: .3rem solid #989898;

      .section-title {
        padding: 0;
      }
    }

    .first-section {
      margin-bottom: 100px;

      &:after {
        position: absolute;
        bottom: -50px;
        content: "";
        border-bottom: solid 1px gray;
        width: 100%;
        left: 0;
      }
    }

    .logo-container img {
      max-width: 50%;
      height: auto;
      display: block;
      margin: 0 auto;
    }

    .pdf-link {
      color: #989898;
      background: white;
      padding: 22px 0 28px 0;
      border-radius: 17px;
      border: solid 1.5px #989898;
      display: block;
      width: 50%;
      text-align: center;
      margin-top: 40px;

      &:hover {
        color: white;
        background: #989898;
      }
    }
  }

  #section-icon-teaser, #bob-section {
    color: #1f3c64;
  }

  .section-icon-teaser {
    padding: 120px 0 35px;

    &.section {
      padding: 70px 0;
    }

    .feature-title {
      font-size: 44px;
      color: rgb(163, 199, 64);
      line-height: 1.1;
      padding: 50px;
      text-align: center;
    }

    .feature-img {
      min-height: 75px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 25px;

      img {
        max-height: 75px;
        width: auto;
      }
    }

    .feature-text {
      text-align: center;
    }
  }

  .section-icon-teaser__title {
    font-weight: 700;
    font-size: 1rem;
    line-height: 1rem;
    letter-spacing: 1px;
    color: #e31c20;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 2rem;
  }

  #section-icon-teaser p {
    color: #1f3c64;
    font-size: 19px;
    line-height: 35px;
  }

  .icon-teaser__title {
    font-weight: 600;
    font-size: 2rem;
    line-height: 2rem;
    letter-spacing: -0.5px;
    margin: 1.375rem 0 1rem 0;
  }

  .icon-teaser__item {
    -ms-flex: 0 0 33.3333%;
    flex: 0 0 33.3333%;
    max-width: 350px;
  }

  .icon-teasers {
    display: -ms-flexbox;
    display: flex;
    text-align: center;
    justify-content: space-between;
    padding: 0 60px;
  }

  .icon-teaser__icon img {
    display: inline-block;
    max-width: 187px;
  }

  .link-buttons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .centered-content {
    p, h3 {
      width: 50%;
      margin: 0 auto;
      font-size: 23px;
    }

    h3 {
      font-size: 23px;
      color: #000;
      font-weight: normal;
      margin: 26px auto 12px;
    }
  }

  .margin-auto {
    margin: 0 auto;
    display: block;
  }

  .natural-width {
    width: auto;
  }

  .list-style-none {
    list-style: none;
  }

  .split {
    ul {
      padding: 0;

      h3 {
        font-size: 23px;
      }

      li {
        margin-bottom: 55px;
      }
    }
  }

  .p-b-80 {
    padding-bottom: 80px;
  }

  &.corona-info {
    h1 {
      text-align: center;
      margin-bottom: 80px;
    }

    .top {
      padding-top: 30px;
    }

    #instructions {
      text-align: center;
    }

    .photo-description {
      min-height: 150px;
    }

    .btn {
      height: auto;
      padding: 3px 15px;
      font-family: "NeoSansProMedium", sans-serif;
      border-radius: 60px;

      a {
        color: white;
      }
    }

    .link-container {
      margin-bottom: 30px;
    }

    .youtube-video iframe,
    .vids-container iframe {
      width: 100%;
    }

    .vids-container {
      margin-bottom: 20px;

      p {
        text-align: center;
      }
    }
  }

  .styled-link {
    &:hover {
      text-decoration: underline;
    }
  }
}

.cms-category-structure {
  h1 {
    font-size: 16px;
    text-transform: uppercase;
    padding: 40px 0;
  }

  .category-intro {
    margin-bottom: 50px;

    p {
      font-size: 18px;
      line-height: 27px;
      color: #666;
      text-align: left;
    }
  }

  .sub-categories {
    padding-bottom: 60px;

    &.even {
      .cms-card-container {
        img {
          height: 300px;
        }
      }

      .card-text {
        p {
          height: 120px;
        }
      }
    }
  }

  .cms-card-container {
    background: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    height: 100%;

    img {
      height: 168px;
      width: 100%;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
    }
  }

  .card-text {
    padding: 30px 40px 50px;

    h3 {
      font-size: 20px;
      line-height: 200%;
      text-align: left;
    }

    p {
      font-size: 14px;
      line-height: 28px;
      margin-bottom: 60px;
      height: 200px;
      overflow: hidden;
      text-align: left;
    }

    a {
      font-size: 16px;
      font-weight: bold;
      position: absolute;
      bottom: 50px;
    }
  }

  .mb-20 {
    margin-bottom: 20px;
  }
}

@include media-breakpoint-down(lg) {
  .cms-structure {
    .top-section {
      max-height: none;
    }

    &.corona-info {
      .photo-description {
        min-height: 120px;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .cms-structure {
    .top-container {
      padding: 20px 40px;
    }

    .top-section {
      background: none !important;
      height: auto;
      max-height: none;

      img {
        max-height: 60vh;
      }

      p {
        font-size: 16px;
        line-height: 32px;
        margin-bottom: 25px;
      }
    }

    h1 {
      font-size: 45px;
      line-height: 52px;
    }

    .section p, .top-section p {
      font-size: 18px;
      line-height: 36px;
    }

    .mobile-title, .section-title {
      font-size: 24px;
      line-height: 37px;
      margin: 0 0 20px;
      font-weight: normal;
      text-align: left;
    }

    .mobile-title {
      padding: 0 40px;
    }

    .row.reverse-mobile {
      flex-direction: column-reverse;
    }

    .split .section-title {
      display: none;
    }

    .section-text {
      margin-top: 20px;
      padding: 0 40px;
    }

    .centered-list {
      width: 100%;

      li {
        font-size: 18px;
      }
    }

    .text-only {
      padding: 0 40px;

      .section-text {
        padding: 0;
      }
    }

    .centered-content {
      p, h3 {
        width: 100%;
        font-size: 18px;
      }
    }

    #toggle-email:checked ~ #contact-email,
    #toggle-phone:checked ~ #contact-phone {
      display: inline-block;
      line-height: 28px;
      max-width: 80%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      float: left;
      border-top-right-radius: 68px;
      border-bottom-right-radius: 68px;
      width: 75%;
      padding-left: 0px;
      margin: 0;
    }

    #toggle-email:checked ~ #toggle-email-button,
    #toggle-phone:checked ~ #toggle-phone-button {
      width: 25%;
      padding-right: 0;
      margin: 0;
    }

    .technical-pdf-section {
      .frame-container {
        border: none;
      }

      .logo-container img {
        max-width: none;
      }

      .pdf-link {
        width: 100%;
        max-width: 400px;
      }
    }
  }
  .page-cms.page-content .flex.styled-list li {
    height: 300px;
  }
  .cms-category-structure {
    .sub-categories.even {
      .cms-card-container {
        img {
          height: auto;
        }
      }
    }

    .card-text {
      padding: 20px;

      p {
        margin-bottom: 30px;
        height: auto;
      }

      a {
        bottom: 18px;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .cms-structure {

    .container {
      padding: 0;
    }

    h1, .small-top-heading {
      font-size: 32px;
      line-height: 48px;
      margin-bottom: 31px;
    }

    .btn-link, .btn-link.extra-wide {
      margin: 0 auto;
      width: 100%;
      font-size: 14px;
      line-height: 28px;
    }

    .content {
      max-width: 100%;

      .top-container {
        word-wrap: break-word;
        hyphens: auto;
      }
    }

    .section {
      padding: 0 0 40px 0;

      &.section-icon-teaser {
        padding: 40px 0;
      }

      p, .centered-list li {
        font-size: 16px;
        line-height: 32px;
      }
    }

    .mobile-title {
      padding: 40px 40px 0;
    }

    .btn.big-btn {
      margin: 20px;
      margin-bottom: 0;
    }

    .contact-data {
      margin: 20px;
      padding: 40px 20px;
    }

    .contact-info {
      text-align: center;
      width: 100%;

      a.collapse-span {
        font-size: 14px;
      }
    }

    .contact-img {
      text-align: center;
    }

    #contact-name {
      text-align: center;
    }

    .collapse-button {
      width: 100%;
      border-radius: 68px;
      margin: 0;
    }

    .collapse-info {
      margin: 24px 0;
    }

    .section-title.m-mobile-0 {
      margin: 0;
    }

    .pdf-list {
      border: none;

      li {
        border-bottom: none;
        padding: 16px 20px;

        .list-button span {
          display: none;
        }
      }
    }

    .pdf-description {
      font-size: 14px;
    }

    /*.framed-section {
      .frame-container {
        .section-title {
          padding: 0;
        }
      }
    }*/

    .pdf-icon {
      vertical-align: middle;
      display: inline;
    }

    .technical-pdf-section {
      .frame-container {
        border: none;
        padding: 0 40px;

        .section-title {
          margin: 0;
          margin-bottom: 20px;
        }
      }

      .pdf-link {
        width: 100%;
        max-width: none;
      }
    }

    .icon-teasers {
      display: block;
      padding: 0 40px;
    }

    .section-icon-teaser {
      margin: 30px 0;
      padding: 0;
    }

    .icon-teaser__item {
      margin-bottom: 20px;
      width: 100%;
      max-width: 100%;
    }

    &.corona-info {
      .photo-description {
        min-height: 0;
      }

      .link-container {
        margin: 30px 46px 13px;
        padding: 0;
        text-align: center;
      }
    }
  }
  .page-cms.page-content .flex.styled-list li {
    border: none;
    width: 100%;
    margin: 30px 0;
    height: auto;
    padding: 0;

    div {
      display: flex;
      font-size: 14px;
      line-height: 200%;
      align-items: center;
    }

    img {
      margin: 0 27px 0 0;
    }
  }
  #bob-section {
    width: 80%;
    margin: 0 auto;

    h2 {
      font-size: 32px;
      line-height: 40px;
      padding: 0;
      margin-bottom: 40px;
    }
  }
  .cms-category-structure {
    h1 {
      padding: 26px 0 10px;
    }

    .card-text {
      padding: 10px 40px 35px;

      p {
        margin-bottom: 30px;
        height: 88px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }

      a {
        bottom: 31px;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .cms-structure {
    .banner-image, .mobile-title {
      display: none;
    }

    .top-section .white {
      p, h1 {
        color: white;
      }
    }
  }
}



