.modal-dialog {
  width: 1100px;
  max-width: calc(100% - 60px);
}

.modal-body.container {
  .col-lg-3 {
    @media (max-width: 767px) {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
}

.row.custom-margin {
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }
}
