#content-wrapper {
  width: 100%;

  #main {
    .page-header {
      margin-bottom: 1.563rem;
    }

    .page-content {
      margin-bottom: 1.563rem;

      h6 {
        margin-bottom: 1.125rem;
      }

      #notifications {
        margin-right: -15px;
        margin-left: -15px;
      }

      .advanced-cms-wrapper {
        .m-0 {
          margin: 0 !important;
        }

        .pl-20 {
          padding-left: 20px;
        }

        .fit-bg {
          height: 500px;
        }
      }
    }

    .page-footer {
      margin-bottom: 1.563rem;
    }
  }
}

@media only screen and (max-device-width: 1300px) {
  #content-wrapper {
    #main {
      .advanced-cms-wrapper {
        h1, h2, h3, h4, h5, h6 {
          text-align: left !important;
        }

        h1 {
          font-size: 48px !important;
          line-height: 58px !important;
        }

        h2 {
          font-size: 32px !important;
          line-height: 40px !important;
        }

        .rpl {
          padding-left: 20px;
        }

        .rnp {
          padding: 0 !important;

          > .adcms-hero-wrapper {
            .adcms-hero-fg {
              padding: 50px 15px 15px 15px !important;
            }
          }
        }

        .c-mb-10 {
          > div {
            margin-bottom: 10px !important;
          }
        }

        .pl-20 {
          padding-left: 20px !important;
        }

        .mt-20 {
          margin-top: 20px !important;
        }

        .mb-20 {
          margin-bottom: 20px !important;
        }

        .rnm {
          margin: 0 !important;
        }

        .fs-13 {
          font-size: 13px !important;
        }

        .l-0 {
          left: 0 !important;
        }

        .t-50 {
          top: 50% !important;
        }

        .t-y {
          top: 50%;
          transform: translateY(-50%);
        }

        .h-a {
          height: auto !important;
        }

        .pb-50 {
          padding-bottom: 50px !important;
        }

        .fit-bg {
          height: 150px !important;
        }

        .adcms-teaser-bg {
          min-height: 300px;
        }

        img, picture {
          max-width: 100%;
        }
      }
    }
  }
}
