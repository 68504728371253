@each $distance in $distances {
  .p-#{$distance} {
    padding: #{$distance}px !important;
  }

  .m-#{$distance} {
    margin: #{$distance}px !important;
  }

  @each $p in $positions {
    $position: nth($p, 1);
    $position-short: nth($p, 2);

    .p#{$position-short}-#{$distance} {
      padding-#{$position}: #{$distance}px !important;
    }

    .m#{$position-short}-#{$distance} {
      margin-#{$position}: #{$distance}px !important;
    }
  }
}
