.carousel {
  margin-bottom: rem(24px);
  .direction {
    z-index: auto;
  }
  .carousel-inner {
    /*height: 613px;*/
    height: 100%;
    max-height: 80vh;
  }
  .carousel-item {
    height: 100%;
    img {
      width: 100%;
      margin-left: 0;
    }
    .caption {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      .display-1 {
        display: none;
      }
      .container {
        display: table;
        width: 100%;
        height: 100%;
      }
      .caption-description {
        //display: table-cell;
        //vertical-align: middle;
      }
    }
  }
  .slider-content {
    font-family: NeoSansProMedium, sans-serif;
    color: black;
    padding: 30px 15px;
    .slider-blue {
      color: $crd-secondary-color;
    }
    h1, h2 {
      font-size: rem(72px);
      font-weight: 400;
    }
    h3 {
      font-size: rem(40px);
      font-weight: 400;
    }
    p {
      font-size: rem(18px);
      color: black;
      margin: 35px 0;
    }
    .btn {
      background: $brand-primary;
      color: white;
      border-radius: 32px;
      padding: 0 30px;
      font-size: 13px;
      line-height: 10px;
    }
  }
  .carousel-control {
    opacity: 1;
    .icon-next,
    .icon-prev {
      z-index: 2;
      margin-top: 0;
      transform: translateY(-16px);
      width: initial;
      height: initial;
      &::before {
        content: "";
      }
      i {
        font-size: rem(30px);
        color: white;
        text-shadow: none;
        background: $very-light-grey;
        border-radius: 50%;
        width: 38px;
        height: 38px;
        text-align: center;
        line-height: rem(40px);
      }
      &:hover {
        i {
          background-color: $gray;
        }
      }
    }
    .icon-prev {
      left: rem(32px);
      margin-left: 0;
    }
    .icon-next {
      right: rem(32px);
      margin-right: 0;
    }
    &.left,
    &.right {
      background: none;
    }
  }
}

//styles for responsive video in slider
/*
#carousel {
  ul.carousel-inner {
    position: relative;
    padding-bottom: 36.25%;
    height: 0;
  }
  li.video-container {
    height: initial;
  }
  .caption {
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100% !important;
      height: 100% !important;
    }
  }
}
*/

/*** Responsive part ***/

@include media-breakpoint-down(md) {
  .carousel {
    .carousel-inner {
      height: 289px;
    }
    .slider-content {
      h1, h2 {
        font-size: rem(38px);
      }
      h3 {
        font-size: rem(20px);
      }
      p {
        font-size: rem(13px);
        margin: 17px 0;
      }
      .btn {
        padding: 0 20px;
      }
    }
    .carousel-item {
      .caption {
        .container {
          width: 80%;
        }
      }
    }
  }
}
