#js-product-list {
  .products-flex {
    .product-block {
      margin-bottom: 20px;
    }
    @media (min-width: 1200px) {
      @include flexboxGridMixin(3, 36, 36);
    }
    @media (max-width: 1200px) and (min-width: 601px) {
      @include flexboxGridMixin(2, 20, 20);
    }
    @media (max-width: 600px) {
      @include flexboxGridMixin(1, 0, 20);
    }
  }
}

.products-in-category {
  .products-flex {
    @media (min-width: 1380px) {
      @include flexboxGridMixin(4, 40, 40);
    }
    @media (max-width: 1379px) and (min-width: 1025px) {
      @include flexboxGridMixin(3, 20, 20);
    }
    @media (max-width: 1024px) and (min-width: 601px)  {
      @include flexboxGridMixin(2, 20, 20);
    }
    @media (max-width: 600px) {
      @include flexboxGridMixin(1, 0, 20);
    }
  }
}

.products-selection .sort-by-row {
  justify-content: flex-end;

  .filter-box {
    margin-left: 20px;
    @media (max-width: 767px) {
      margin-left: 0;
      &:last-child {
        margin-left: 10px;
      }
    }
  }
}

.pagination .page-list {
  background: none;
}

#sorting-top .js-product-list-selection {
  margin-bottom: 0;
  padding-left: 20px;
  border-bottom: 0;
}

.js-product-list-selection {
  .pagination {
    background: #fff;
    font-size: 12px;
    font-weight: bold;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 20px;
    padding: 3px 15px;

    li a {
      min-width: 30px;
      height: 30px;
      line-height: 26px;
      border: 0;
      font-weight: bold;
      color: $crd-primary-color;
    }

    li .spacer {
      min-width: 30px;
    }
  }

  .filter-label {
    font-size: 12px;
    font-weight: bold;
    color: #666;
  }

  .select-title {
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    height: 36px;
    border-radius: 24px;
    line-height: 34px;
    font-size: 12px;
    font-weight: bold;
    padding: 0 15px;
    color: $crd-primary-color;
    border: none;

    .material-icons {
      line-height: 34px;
      height: 36px;
      color: $crd-primary-color;
      margin: 2px 0 0 2px;
    }
  }
}

.page-category,
.page-search {
  #wrapper {
    padding-top: 25px;
  }

  #sorting-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
    padding-top: 20px;

    .category-title {
      font-size: 16px;
      text-transform: uppercase;
      margin: 0;
      padding: 0;
    }

    .js-product-list-selection {
      padding: 0;
    }
  }
}

#breadcrumbs {
  background: #fff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);

  .breadcrumb {
    background: transparent;
    margin: 0;
    padding: 14px 0;
    font-size: 13px;
    line-height: 19px;
    color: $crd-primary-color;

    &[data-depth="1"] {
      display: none;
    }

    ol {
      padding-left: 0;
      margin-bottom: 0;
    }

    a {
      color: $crd-primary-color;
    }

    li {
      display: inline;

      &::after {
        content: "";
        background: url('../img/icons/arrow-right-breadcrumbs.svg') no-repeat center left;
        color: $crd-primary-color;
        width: 7px;
        height: 10px;
        margin: 0 6px;
        display: inline-block;
      }

      &:last-child {
        &::after {
          background: none;
        }

        a {
          color: $gray;
        }
      }
    }
  }
}

body.lock-scroll-filter {
  overflow: hidden;
}

.open-filters {
  background: #fff url('../img/icons/filter-icon.svg') no-repeat 15px center;
  color: $crd-primary-color;
  font-size: 12px;
  line-height: 36px;
  text-transform: uppercase;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  display: none;
  padding: 0 15px 0 37px;
  font-weight: bold;
}

.af_filter label {
  font-size: 12px;
}

.af_filter .count:before, .af_filter .af-total-count:before,
.af_filter .count:after, .af_filter .af-total-count:after {
  content: '';
}

#amazzing_filter {
  .block_content.allow-scroll {
    overflow: hidden;
  }

  h3 {
    background: url('../img/icons/filter-icon.svg') no-repeat center left;
    color: $crd-primary-color;
    font-size: 12px;
    line-height: 36px;
    padding-left: 22px;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  .af_filter {
    margin: 0;
    padding: 0;
    border: 0;

    .af_subtitle {
      font-size: 12px;
      line-height: 40px;
      font-family: GalaxiePolarisBook, sans-serif;
      border-bottom: 1px solid #C4C4C4;
      font-weight: bold;
      margin-bottom: 0;
      color: #000;
      position: relative;
      &:after {
        display: none;
      }
      .hidden-on-open {
        display: none;
      }
      .visible-on-open {
        display: inline-block;
      }
    }
    &.closed {
      .af_subtitle {
        .hidden-on-open {
          display: inline-block;
        }
        .visible-on-open {
          display: none;
        }
      }
    }
    > ul.open {
      display: block;
      margin: 15px 0;
    }
    .af-quick-search {
      display: block;
      margin-top: 15px;
      &:before {
        border: 2px solid #C4C4C4;
        top: 12px;
        right: 12px;
      }
      &:after {
        border: 1px solid #C4C4C4;
        top: 13px;
        right: 12px;
      }
      .qsInput {
        background: white;
        border: 1px solid #C4C4C4;
        border-radius: 20px;
        position: relative;
        font-size: 14px;
        line-height: 36px;
        padding: 0 40px 0 10px;
      }
    }
    .toggle-cut-off span {
      background: none;
      color: $crd-primary-color;
    }
  }

  .af_filter.foldered .af-parent-category label {
    padding-right: 0;
    width: 80%;
  }
  .af_filter label {
    line-height: 36px;
    display: block;
    padding: 0;
    margin: 0;
  }

  .af_filter .name.side-label {
    display: inline-block;
    position: relative;
    margin: 0;
    padding-left: 25px;
    cursor: pointer;
  }
  .af_filter .af-toggle-child {
    position: absolute;
    right: 10px;
    top: -1px;
    padding: 0;
  }

  .af_filter li {
    position: relative;
    line-height: 36px;
    padding: 0;
  }

  .af-parent-category > ul {
    width: calc(100% - 15px);
  }

  .af_filter li.active .af-toggle-child .count {
    font-size: 12px;
    color: #9a9a9a;
  }

  .af_filter .af-toggle-child {
    position: absolute;
    right: 10px;
  }
  .af_filter .af-toggle-child,
  .af_filter li.active .name,
  .af_filter .name {
    font-size: 12px;
    color: #000;
  }

  //main dropdown
  .af-toggle-child .hidden-on-open,
  .af-toggle-child .visible-on-open {
    width: 11px;
    height: 11px;
    background: url('../img/icons/arrow-down.svg') no-repeat;
  }

  .af_subtitle .af-toggle-child .visible-on-open {
    transform: scaleX(-1) scaleY(-1);
  }

  //inside dropdown
  ul .af-toggle-child .hidden-on-open {
    background: url('../img/icons/toggle-filter-open.svg') no-repeat;
    margin-bottom: 3px;
  }

  ul .af-toggle-child .visible-on-open {
    background: url('../img/icons/toggle-filter.svg') no-repeat;
    margin-bottom: 3px;
  }

  .af_filter.foldered .af_filter_content > ul.open .af-parent-category {
    .af-toggle-child .visible-on-open {
      display: inline-block;
    }

    .af-toggle-child .hidden-on-open {
      display: none;
    }
  }

  .af_filter .af_subtitle_heading {
    .af-toggle-child {
      top: 15px;
    }

    &.open {
      .af-toggle-child {
        top: 7px;
      }
    }
  }

  form#af_form {
    margin: 0;
    padding: 0;
  }
  .af_subtitle {
    padding: 0;
    margin: 0;
  }
  .af_subtitle.toggle-content:before,
  .foldered .af-toggle-child:after{
    display: none;
  }
  .selectedFilters {
    font-size: 12px;
    font-family: GalaxiePolarisBook, sans-serif;
    a:not(.all) {
      font-size: 12px;
      font-family: GalaxiePolarisBook, sans-serif;
    }
    .clearAll {
      background: none;
      font-weight: normal;
      padding: 15px 0;
      font-size: 14px;
      border-bottom: 1px solid #e6e6e6;
      margin-bottom: 10px;
      text-align: left;
      span.close {
        font-size: 15px;
      }
    }
    .cf {
      padding: 5px 0;
      background: none;
      a {
        right: 3px;
      }
    }
  }
  .icon-times.close,
  .icon-eraser.all {
    font-size: 12px;
    text-shadow: none;
    opacity: 1;
  }

  .mobile-controls {
    display: none;
  }

  @media (max-width: 1440px) {
    .af_filter li:not(.af-parent-category),
    .af_filter li label {
      line-height: 32px;
      height: 32px;
    }
  }

  .close-filter {
    display: none;
  }
}

.lock-scroll-filter #mm-blocker {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  display: block;
}

#amazzing_filter .selectedFilters .clearAll {
  font-weight: normal;
  padding: 15px 0;
}
.modal {
  .product-miniature.js-product-miniature {
    margin-bottom: 30px;
  }
}

.info-assortment.fa-info-circle {
  color: #ff4c4c;
}

.category-title-top {
    font-family: NeoSansProStd, sans-serif;
    font-size: 26px;
    color: $crd-h1;
    font-weight: normal;
    text-transform: uppercase;
    margin: 40px 0;
}

.prod-tooltip {
  display: none;
}

.prod-info-tooltip {
  border-radius: 8px;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
  border: none;

  .popover-body {
    padding: 0;
  }

  > .arrow::before {
    border-color: transparent !important;
  }

  h3 {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: $gray-darker;
    margin: 7px 0 0px;

  }

  .display-flex {
    display: flex;
    justify-content: space-between;
  }

  .bestseller {
    font-weight: 600;
    font-size: 12px;
    color: $brand-primary;

    &:before {
      content: "";
      background: url('../img/icons/wreath-icon.svg') no-repeat left center;
      width: 16px;
      height: 16px;
      display: inline-block;
      position: relative;
      top: 4px;
      margin-right: 10px;
    }
  }

  .top {
    padding: 20px 20px 10px 20px;

    span {
      line-height: 13px;
    }

    .product-availability {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      display: inline-block;
      position: relative;
      top: 2px;
      left: 0;
      margin-right: 3px;
      line-height: 0;

      &.in-stock {
        background: #14B71A;
      }

      &.out-of-stock {
        background: #f0ad4e;
      }
    }
  }

  .middle {
    padding: 10px 20px;

    p {
      margin: 0;
    }
  }

  .bottom {
    border-top: 1px solid #F6F6F6;
    padding: 10px 20px 20px 20px;

    span {
      font-weight: 600;
      font-size: 12px;
      line-height: 12px;
      color: #DD0000;
    }
  }
}


.prod-ordered-tooltip {
  border-radius: 8px;
  background: #000;
  opacity: 0.8;
  border-color: #000;
  overflow: hidden;

  h3 {
    background: #000;
    font-weight: bold;
    font-size: 10px;
    line-height: 14px;
    color: #fff;
    margin: 0;
    border: 0;
    border-radius: 0;
  }
}

.category-seo {
  pre {
    white-space: pre-wrap;
    font-size: 14px;
    color: #878787;
    line-height: 20px;
    font-style: normal;
  }
}

.bg-gray-light {
  background: $gray-lighter;
}

#category main, #search main {
  background: $gray-lighter;
}

@media (min-width: 1025px){
  #category.layout-left-column,
  #search.layout-left-column {
    #left-column.col-md-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }
    #content-wrapper.col-md-9 {
      flex: 0 0 75%;
      max-width: 75%;
    }
  }
}

@media (max-width: 360px){
  .filter-box.amz-filter.js-hide-for-quickorder.clearfix.pagination-cont {
  margin-left: 5px;
  }
  .pagination.pull-right{
    padding-left: 0 ;
    padding-right: 0 ;
  }
}

@media (max-width: 1025px) {
  .page-category #sorting-top,
  .page-search #sorting-top {
    flex-direction: row-reverse;
  }
  .products-selection .sort-by-row {
    justify-content: center;
  }
  .products-selection .sort-by-row .filter-box.sort-by-criteria {
    margin: 0;
  }
  .filter-box .filter-label {
    display: none;
  }
  .open-filters {
    display: inline-block;
  }
  .page-category,
  .page-search {
    #left-column.col-md-3 {
      position: fixed;
      top: 0;
      background: #fff;
      width: 38%;
      max-width: 38%;
      overflow-y: scroll;
      z-index: 1000;
      height: 100%;
      padding: 0;
      transition: none 0.4s ease;
      transition-property: top, right, bottom, left, border;
      right: -900px;

      &.open {
        right: 0;
      }
    }

    #content-wrapper.col-md-9 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  #amazzing_filter {
    position: relative;
    padding-top: 20px;
    min-height: 100%;
    box-sizing: border-box;

    h3 {
      margin: 0 25px 20px 25px;
      position: relative;
    }

    .block_content {
      padding: 0 25px;

      &.allow-scroll {
        overflow: hidden;
        overflow-y: scroll;
      }
    }

    .mobile-controls {
      height: 80px;
      background: #F6F6F6;
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 15px 20px;
      box-sizing: border-box;
      position: absolute;
      bottom: 0;
      width: 100%;
      z-index: 1;

      a:not(.new-btn-secondary):not(.new-btn-primary) {
        width: 50%;
        color: $crd-primary-color;
        text-align: center;
        font-weight: bold;
        font-size: 16px;
        line-height: 50px;
        text-transform: uppercase;

        &:hover {
          background: $crd-primary-color;
          color: #fff;
          border-radius: 40px;
        }

        &.btn {
          color: white;
          border-radius: 25px;
          line-height: 47px !important;
          height: 50px;
        }
      }
    }

    .close-filter {
      content: '';
      background: url('../img/icons/close-blue-icon.svg') no-repeat;
      background-size: 12px;
      display: inline-block;
      z-index: 1;
      right: 0;
      position: absolute;
      width: 16px;
      height: 16px;
      top: 11px;
    }

    .selectedFilters {
      display: none;
    }
  }
  .af_filter .af-toggle-child {
    right: 5px !important;
  }
  .af_filter .count {
    display: inline-block;
  }
  .prod-info-tooltip {
    display: none;
  }
}

@media (max-width: 768px) {
  .page-category,
  .page-search {
    #left-column.col-md-3 {
      width: 48%;
      max-width: 48%;
    }
  }
}

@media (max-width: 500px) {
  .page-category,
  .page-search {
    #left-column.col-md-3 {
      width: 100%;
      max-width: 100%;
    }
  }

  .products-selection .sort-by-row {
    display: flex;
  }

  .category-title {
    display: none;
  }
  #module-crd_history-historyproducts .category-title {
    display: block;
    margin-bottom: 10px;
  }
}

@media (max-width: 414px) {
  .js-product-list-selection {
    .pagination li a, .select-title span {
      line-height: 20px;
      min-width: 25px;
      height: 25px;
    }

    .select-title {
      line-height: 31px;
      height: 31px;

      .material-icons {
        line-height: 31px;
      }
    }
  }
}
