/*******************right shopping cart***************************/

.right-cart-header {
  margin: 24px 0 30px;

  h2 {
    float: left;
    margin: 0;
    padding: 0;
  }
}

.print_cart {
  float: right;

  a {
    color: $gray-darker;
    background: none;
    box-shadow: none;
    font-size: 21px;

    :hover {
      color: $brand-primary;
    }
  }
}

.above-checkout {
  .print_cart {
    padding-top: 6px;
  }

  .back-btn {
    float: left;
  }
}

html.overflow-hidden {
  overflow: hidden;
  height: 100vh;
}

#cart-dropdown {
  display: none !important;
}

#shadow-layer {
  position: fixed;
  min-height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(67, 87, 121, 0.6);
  cursor: pointer;
  z-index: 1040;
  display: none;
}

#shadow-layer.is-visible {
  display: block;
  -webkit-animation: cd-fade-in 0.3s;
  -moz-animation: cd-fade-in 0.3s;
  animation: cd-fade-in 0.3s;
}

@-webkit-keyframes cd-fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes cd-fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes cd-fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

#header .right-cart-summary .one-order-button .btn-primary {
  color: white;
}

#right-side-cart-background.speed-in {
  right: 0;
  transition: all 0.5s ease;

  .right-cart-summary {
    right: 0;
    transition: all 0.5s ease;
  }
}

.gritter-notification-btns {
  .btn {
    margin: 0 auto;
  }
}

#right-side-cart-background {
  position: fixed;
  top: 0;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 1050;
  width: 75%;
  min-width: 670px;
  right: -100%;
  background: white;
  padding: 20px;
  transition: all 0.5s ease;

  #close-right-cart {
    position: absolute;
    right: 14px;
    top: 4px;
    color: #003B5C;
    background: none;
    padding: 0;
    margin: 0;
    box-shadow: none;
    font-size: 20px;
    border: none;
  }

  h2 {
    font-size: 16px;
    font-weight: 700;
    color: $crd-primary-color;
    text-transform: uppercase;
    position: relative;
    top: 4px;

    &:after {
      content: "";
      background: $gray-light2;
      position: absolute;
      top: 25px;
      left: 0;
      height: 1px;
      width: 50px;
    }
  }

  .articles {
    padding-bottom: 300px;
  }

  article {
    vertical-align: middle;
    text-align: center;
    padding: 0 0 10px 0;

    .article-content {
      display: flex;
      border: 1px solid $gray-medium;
      padding: 10px 0;
      border-radius: 7px;
      align-items: center;
    }

    .article-image {
      width: 130px;
      padding: 0 8px;

      img {
        max-width: 100%;
      }
    }

    .article-name {
      width: calc(100% - 370px);

      h3 {
        font-size: 14px;
        line-height: 20px;
        color: $brand-primary-title;
        font-weight: 700;
        text-align: left;
        margin-bottom: 0;
      }
    }

    .article-price {
      font-size: 14px;
      font-weight: 700;
      font-family: $mainFontFamily, sans-serif;
      color: $brand-primary;
      line-height: 14px;
      margin: 0;
      text-align: center;
      width: 190px;
    }

    .article-qty {
      width: 134px;

      form {
        margin: 0 auto;
      }

      .input-box {
        max-width: 85px;
        position: relative;

        input.qty-input {
          display: block;
          text-align: center;
          border: 1px solid $gray-medium;
          border-radius: 19px;
          margin: 0;
          width: 100%;
          padding: 0;
          font-weight: 700;
          font-size: 14px;
          height: 34px;
          line-height: 34px;
        }
      }

      .value-button {
        top: 8px;
        display: block;
        position: absolute;
        color: #939393;
        cursor: pointer;

        .material-icons {
          font-size: 16px;
        }
      }

      .decrease {
        left: 5px;
      }

      .value-button.increase {
        right: 5px;
        left: auto;
      }
    }

    .remove-article {
      width: 50px;
      text-align: center;
      font-size: 20px;
    }

    .product-information {
      display: block;
      text-align: left;
      margin: 5px 0 0;
      font-weight: bold;
    }
  }
}

.right-cart-summary {
  font-family: $mainFontFamily;
  background: $crd-primary-color;
  padding: 20px 0;
  margin: 30px 0 0;
  position: fixed;
  right: -100%;
  width: 75%;
  min-width: 670px;
  bottom: 0;
  transition: all 0.5s ease;

  .total-articles {
    font-size: 13px;
    font-weight: 700;
    color: white;
    margin: 0;

    .material-icons {
      font-size: 14px;
      color: $crd-primary-color;
      margin-top: -3px;
    }
  }

  .total-label {
    font-size: 13px;
    font-weight: 700;
    color: #232323;
    margin-bottom: 12px;
  }

  .total-price {
    font-size: 18px;
    font-weight: 700;
    color: $crd-secondary-color;
    margin-bottom: 15px;
  }

  .totals {
    border: none;
    padding: 0;
    border-radius: 5px;
    margin: 0 20px 24px;

    > div {
      padding: 7px 0;
    }
  }

  .separator {
    border-bottom: 1px solid $gray-medium;
  }

  .totals-title {
    font-weight: 700;
    font-size: 14px;
    color: white;
    text-transform: uppercase;
    line-height: 14px;
  }

  .labels, .values {
    font-size: 14px;
    font-weight: 700;
    color: white;
  }

  .labels {
    font-size: 13px;
    font-weight: 700;
    color: white;
  }

  .small-order-tax {
    .labels, .values {
      color: #ff5048;
    }
  }

  .total-label {
    font-size: 14px;
    color: white;
    font-weight: 700;
  }

  .total-price {
    color: white;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
  }

  .text-right {
    text-align: right;
  }

  #open-totals {
    background: none;
    border: none;
    color: white;
    margin: 0 13px 20px;
    font-size: 15px;

    &:focus {
      outline: none;
    }

    .fa {
      transition: all 0.4s ease;
    }

    &.down {
      .fa {
        transform: rotateZ(180deg);
      }
    }
  }

  #total-outside-summary {
    margin: 0px 20px 20px;

    .total-label {
      font-size: 20px;
    }
  }

  #hide-summary {
    display: none;
  }

  .one-order-button {
    text-align: center;

    .btn-primary {
      background: $crd-secondary-color;
      color: white;
    }
  }
}

.empty-cart {
  margin: 80px auto;
  text-align: center;
  max-width: 80%;
  padding: 45px 50px;
  width: 70%;
  border-radius: 30px;
  border: 2px solid $crd-primary-color;

  .empty-cart-icon {
    width: 120px;
    height: 120px;
    font-size: 120px;
    color: blue;
    background-size: 100%;
    display: block;
    margin: 0 auto 40px;
    color: $crd-primary-color;
  }

  .title {
    font-size: 25px;
    font-weight: 700;
    color: $crd-primary-color;
  }
}

@media (max-width: 600px) {
  #right-side-cart-background,
  .right-cart-summary {
    width: 100%;

    .totals .col-lg-4 {
      width: calc(100% / 3);
    }

    .totals .col-lg-8 {
      width: calc(100% / 3 * 2);
    }
  }
}

/******************* end right shopping cart ******************/
