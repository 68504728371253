.right-cart-summary,
#right-side-cart-background {
  max-width: 600px;
  min-width: auto;
}

#right-side-cart-background {
  padding: 25px 15px;
}

#right-side-cart-background,
#confirmCheckout {

  #close-right-cart {
    right: 30px;
    top: 32px;

    i.close-right-cart {
      background: url('../img/icons/close-blue-icon.svg') no-repeat;
      width: 16px;
      height: 16px;
      display: inline-block;
    }
  }

  .right-cart-header {
    margin: 0 15px 40px 15px;

    h3 {
      font-size: 18px;
      font-weight: normal;
      line-height: 27px;
      color: #000;
    }

    .print_cart {
      float: none;

      a {
        background: url('../img/icons/print-cart.svg') no-repeat left center;
        color: $crd-primary-color;
        font-size: 12px;
        line-height: 18px;

        .value {
          padding-left: 25px;
        }
      }
    }
  }

  .empty-cart {
    text-align: center;
    width: 100%;
    border-radius: 0;
    border: 0;
    max-width: 100%;
    margin-top: 50%;

    .empty-cart-icon {
      background: url('../img/icons/empty-cart.svg') no-repeat;
      width: 72px;
      height: 66px;
    }

    p.title {
      font-size: 18px;
      font-weight: normal;
      line-height: 27px;
      color: #000;
      margin: 20px auto;
    }

    p.text {
      font-size: 13px;
      color: #666;
      line-height: 20px;
      margin: 0 0 30px 0;
    }

    p.buttons {
      a {
        font-weight: bold;
        color: $crd-primary-color;
        font-size: 12px;
        padding: 11px 30px;
        display: block;

        &:first-child {
          margin-bottom: 10px;
        }

        &:hover {
          color: $nav-links-color;
          background: $crd-secondary-color;
          border-radius: 37px;
        }
      }
    }
  }

  article {
    padding: 0 0 30px 0;

    .article-image {
      padding: 0;
      width: 80px;
      margin-right: 20px;
    }

    .article-content {
      border: 0;
      padding: 0;
      border-radius: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .article-details {
      flex-direction: column;
      align-items: flex-start;
      width: calc(100% - 250px);
      text-align: left;

      .article-name {
        width: 100%;

        a {
          font-size: 13px;
          line-height: 20px;
          color: #000;
          font-weight: normal;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          width: 100%;
          display: block;
        }

        .product-information {
          font-size: 12px;
          color: #9A9A9A;
          line-height: 18px;
          font-weight: normal;
          margin: 0;
        }
      }

      .article-price {
        font-size: 13px;
        font-weight: bold;
        color: $crd-primary-color;
        text-align: left;
        line-height: 20px;
      }
    }

    .remove-article {
      //display: none;
    }

    .article-qty {
      width: 80px;
      margin-left: 20px;
      @media(max-width: 1080px) {
        margin-left: 25px;
      }
      .input-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 0;
        font-size: 12px;

        input.qty-input {
          line-height: 30px;
          border: 0;
          color: $crd-primary-color;
          font-weight: normal;
          text-align: center;
          background: transparent;
        }

        .value-button {
          position: inherit;
          top: auto;
          left: auto;
          right: auto;
          width: 20px;
          height: 20px;
          text-align: center;

          &.decrease {
            background: url('../img/icons/minus-icon.svg') no-repeat;
          }

          &.increase {
            background: url('../img/icons/plus-icon.svg') no-repeat;
          }
        }
      }
    }

    .remove-article {
      min-width: 10px;
    }
  }

  .right-cart-summary {
    background: #fff;
    box-shadow: 0px -4px 26px rgba(0, 0, 0, 0.05);
    padding: 10px 0 0 0;

    .row {
      margin: 0;
    }

    .totals {
      margin: 0;

      > div {
        padding: 10px 0;

        &.total {
          padding: 0;
        }
      }

      .separator {
        border: 0;
      }

      .labels {
        color: #666;
        font-weight: normal;
        padding-left: 30px;
      }

      .values {
        color: $crd-primary-color;
        font-weight: bold;
      }

      .total_discount {
        .labels {
          color: $accent-green;
        }
      }

      .total {
        background: #F1F1F1;

        .checkout-url {
          background: $crd-primary-color;
          color: #fff;
          text-align: center;
          padding: unset;

          &:hover {
            background: $crd-secondary-color;
            color: #fff;
          }

          a, a:hover {
            background: none;
            color: inherit;
            font-size: 16px;
            line-height: 60px;
            font-weight: bold;
            text-transform: uppercase;
          }
        }

        .total-label,
        .total-price {
          color: $crd-primary-color;
          font-weight: bold;
          font-size: 16px;
          line-height: 60px;
        }

        .total-label {
          color: #666;
          padding-left: 30px;
          white-space: nowrap;
        }
      }

      .row {
        margin: 0;
      }
    }
  }

  .btn-checkout {
    display: block;
  }

  .info-discount.fa-info-circle,
  {
    margin: 0px 4px;
    float: none;
    font-size: 13px;
  }
}

@media (max-width: 600px) {
  #right-side-cart-background,
  .right-cart-summary {
    width: 100%;

    .totals .col-lg-4 {
      width: calc(100% / 2);
    }

    .totals .col-lg-8 {
      width: calc(100% / 3 * 2);
    }
  }

  #right-side-cart-background {
    .right-cart-summary {
      .totals {
        .row {
          margin: 0;
        }

        .col-lg-4 {
          width: calc(100% / 2);

          &:first-child {
            padding-left: 30px;
          }

          &.checkout-url {
            width: 100%;
          }

          .labels {
            padding-left: 0;
          }
        }

        .col-lg-8 {
          width: calc(100% / 3 * 2);
        }

        .total {
          .total-label,
          .total-price {
            color: $crd-primary-color;
            font-weight: bold;
            font-size: 13px;
            line-height: 60px;
          }

          .total-label {
            color: #666;
            padding-left: 0;
          }
          .checkout-url {
            a, .checkout-url a:hover {
              font-size: 13px;
            }
          }
        }
      }
    }
  }
}

@media (max-height: 450px) and (max-width: 767px) {
  #supercheckout-fieldset .supercheckout-new .col-right {
    display: none !important;
  }
}

@media (max-height: 450px) {
  #right-side-cart-background {
    padding: 25px 0 0 0;
  }
  #right-side-cart-background .articles.has-articles, #confirmCheckout .articles.has-articles {
    padding: 0 15px;
  }
  .right-cart-summary {
    position: inherit;
    width: 100%;
  }

  .mm-list > li.my-account {
    position: relative;
  }

}
