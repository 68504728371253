.block_newsletter {
  @include search-box;
  font-size: $font-size-sm;

  p {
    padding-top: $small-space;
  }

  form {
    .input-wrapper {
      overflow: hidden;
    }

    input[type=text] {
      padding: 0 22px;
      line-height: 50px;

      &:focus {
        outline: 0;
      }

      width: 100%;
      margin-top: 0px;
      border: 2px solid #D1D1D1;
      border-radius: 4px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      height: 50px;
      border-right: 0;
    }

    button[type=submit].btn-nsl {
      position: static;
      width: 100%;
      margin: 0px;
      border: 0;
      border-radius: 4px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      height: 50px;
    }
  }

  margin-bottom: rem(83px);

  h2 {
    font-family: $headingFontFamily, sans-serif;
    font-weight: 400;
    line-height: rem(20px);
    margin-top: rem(76px);
    margin-bottom: rem(47px);
    text-align: center;
  }

  .input-group {
    width: 369px;
    margin: 0 auto;

    .btn-primary {
      background: $crd-secondary-color;
      padding: 0 15px;
      border-top-right-radius: rem(21px);
      border-bottom-right-radius: rem(21px);
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      line-height: 44px !important;
      font-family: $headingFontFamily, sans-serif;
      font-weight: 400;
    }
  }

  .alert {
    max-width: 369px;
    margin: 25px auto 0;
    padding: 14px 10px;
  }
}

.footer-container {
  #footer-popup {
    width: 212px;
    margin: 0 auto;
    display: flex;
    padding: 75px 0 59px;

    .contact-1 {
      font-size: 22px;
      min-height: 25px;
      width: 132px;
      color: rgb(0, 59, 92);
      display: block;
      line-height: 22px;
    }

    .contact-2 {
      font-size: 13px;
      width: 132px;
      color: rgb(0, 175, 215);
      display: block;
      line-height: 22px;
    }

    .contact-icon {
      margin-left: 20px;
    }
  }

  .links {
    &.block-contact {
      .contact-section {
        background: none;
        padding: 0;
        opacity: 1;
        background-color: rgb(255, 255, 255);

        span {
          opacity: 1;
          font-family: NeoSansProLight, sans-serif;
          font-weight: 600;
          font-style: normal;
          text-align: right;
        }

        .row img {
          position: absolute;
          border-radius: 8.36983px 8.36983px 8.36983px 2.092458px;
          opacity: 1;
        }

        > .container {
          background: #303030;
          color: $nav-links-color;
          max-width: 100%;
          background-color: rgb(255, 255, 255);

          .row {
            display: none;
          }

          .col-sm-3, .col-sm-4 {
            text-align: left;
          }

          .store-info-title {
            font-size: 12px;
            line-height: 18px;
            font-weight: 700;
            text-transform: uppercase;
            margin-bottom: 6px;
          }

          .padded-left .contact-details {
            display: flex;
            align-items: center;
            justify-content: left;

            a {
              color: #fff;
              min-width: 36px;
              height: 36px;
              line-height: 32px;
              border: 1px solid #fff;
              border-radius: 18px;
              display: inline-block;
              position: relative;

              &:hover {
                text-decoration: none;
              }

              &.phone {
                background: url('../img/icons/phone-icon.svg') no-repeat 9px center;
                margin-right: 15px;
              }

              &.email {
                background: url('../img/icons/email-icon.svg') no-repeat 9px center;
              }

              &.teamviewer {
                background: url('../img/icons/team-viewer-white.svg') no-repeat center;
                background-size: 19px 20px;
              }

              span.blank {
                width: 36px;
                height: 32px;
                position: absolute;
                left: 0;
                top: 0;
                display: block;
              }

              span.value {
                width: 0;
                display: none;
                padding-left: 36px;
                padding-right: 9px;
                -webkit-transition: width 1s ease-in-out;
                -moz-transition: width 1s ease-in-out;
                -o-transition: width 1s ease-in-out;
                transition: width 1s ease-in-out;
              }

              &.show {
                width: auto;

                span.value {
                  width: auto;
                  display: inline-block;
                }
              }
            }
          }
        }
      }
    }

    &.footer-links {
      padding-top: 32px;
      padding-bottom: 40px;

      ul.collapsing {
        transition: none;
      }

      .title {
        font-size: 12px;
        line-height: 18px;
        padding-bottom: 16px;
        max-width: none;
      }

      .col-md-3.wrapper {
        flex: 0 0 25%;
        max-width: 25%;

        .title .h3 {
          color: rgb(89, 89, 89);
          font-family: $mainFontFamily;
          font-size: 14px;
          font-weight: 700;
          font-style: normal;
          text-align: left;
          line-height: 22px;
        }

        .title .float-sm-right .material-icons {
          color: rgb(89, 89, 89);
        }
      }
    }

    .navbar-toggler {
      color: $nav-links-color;
      display: none;
    }

    span.h3, p.h3 {
      font-size: 12px;
      line-height: 18px;
      font-weight: 700;
      text-transform: none;
      color: $nav-links-color;
    }

    ul {
      display: inherit;
      justify-content: center;
      padding: 0;
      margin: 0;
    }

    li {
      width: 100%;
      padding: 6px 0;
      margin: 0;
      line-height: 18px;

      a {
        color: #BBCCDE;
        line-height: 18px;

        &:hover {
          color: $nav-links-color;
        }

        &:focus {
          text-decoration: none;
        }
      }

      .current-page {
        text-decoration: none;
      }
    }

    hr {
      margin-top: 24px;
    }
  }

  .footer-border {
    border-bottom: 0;
  }

  .block-newsletter {
    background: #2E5989;
    font-size: 12px;
    line-height: 18px;
    padding: 16px 0;
    text-align: center;
    width: 100%;

    #newsletter-text {
      width: 100%;
      margin: 0;
      max-width: 100%;
      padding: 0 30px;
      text-align: center;

      .fa {
        padding-right: 5px;
      }

      a {
        color: $nav-links-color;
        font-weight: bold;

        &:hover {
          color: #BBCCDE;
        }
      }
    }
  }

  .block-social {
    margin: auto;

    ul {
      margin: 0;
      padding-bottom: 0;

      li {
        background: none;
        height: 28px;
        width: 28px;
        padding: 4px 0;

        a {
          color: $nav-links-color;
          font-size: 20px;
          line-height: 15px;
        }
      }
    }
  }

  .copyright {
    line-height: 60px;
    margin: 0;
    padding: 0;
    text-align: center;
    color: #B6C5D6;
    font-size: 12px;
  }
}

@include media-breakpoint-down(sm) {
  .footer-container {
    .links {
      &.footer-links {
        padding-top: 0;
        padding-bottom: 0;

        .col-md-3.wrapper {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }

      .collapse.show {
        display: block;
      }

      .float-sm-right {
        display: inline-block;
        float: right;
      }

      .navbar-toggler {
        display: inline-block;
        vertical-align: middle;

        .fa-angle-up {
          display: none;
        }
      }

      ul {
        display: none;

        > li {
          padding-left: 0;
          padding-right: 0;
        }
      }

      .title {
        padding: 20px 0;
      }

      .title[aria-expanded='true'] {
        .navbar-toggler {
          .fa-angle-up {
            display: block;
          }

          .fa-angle-down {
            display: none;
          }
        }
      }

      > .container {
        padding-left: 0;
        padding-right: 0;
      }

      &.block-contact .contact-section {
        > .container {
          padding-top: 0;
          padding-bottom: 0;

          .col-sm-3 {
            padding: 10px 30px;
            border-bottom: 1px solid #4A4A4A;
          }

          .padded-left {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .store-info-title {
              margin: 0;
              padding: 0;
              line-height: 36px;
            }

            .contact-details a.show {
              min-width: 36px;

              span.value {
                display: none;
              }
            }
          }
        }
      }
    }

    .block-social {
      position: static;
      text-align: center;
      left: auto;
      bottom: auto;
      width: 100%;
      padding: 10px 0;
    }

    .col-sm-3 {
      width: 100%;
      max-width: none;
      display: block;
      flex: none;
    }
  }
}

@media (max-width: 450px) {
  .float-sm-right {
    float: right !important;
  }

  .block_newsletter .input-group {
    width: calc(100% - 30px);
  }

  .footer-container {
    .block-social {
      ul {
        display: flex;
        align-items: center;

        li a {
          line-height: 15px;
        }
      }
    }
  }

  .footer-container .links.block-contact .contact-section > .container .padded-left {
    display: inline-block;
  }
}
