.block_newsletter {
  @include search-box;
  font-size: $font-size-sm;

  p {
    padding-top: $small-space;
  }

  form {
    .input-wrapper {
      overflow: hidden;
    }

    input[type=text] {
      padding: 0 22px;
      line-height: 50px;

      &:focus {
        outline: 0;
      }

      width: 100%;
      margin-top: 0px;
      border: 2px solid #D1D1D1;
      border-radius: 4px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      height: 50px;
    }

    button[type=submit].btn-nsl {
      width: 100%;
      margin-top: 0px;
      border: 2px solid #D1D1D1;
      border-radius: 4px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      height: 50px;
    }
  }

  margin-bottom: rem(83px);

  h2 {
    font-family: $headingFontFamily, sans-serif;
    font-weight: 400;
    line-height: rem(20px);
    margin-top: rem(76px);
    margin-bottom: rem(47px);
    text-align: center;
  }

  .input-group {
    width: 369px;
    margin: 0 auto;

    .btn-primary {
      background: $crd-secondary-color;
      padding: 0 15px;
      border-top-right-radius: rem(21px);
      border-bottom-right-radius: rem(21px);
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      line-height: 44px;
      font-family: $headingFontFamily, sans-serif;
      font-weight: 400;
    }
  }

  .alert {
    max-width: 369px;
    margin: 25px auto 0;
    padding: 14px 10px;
  }
}

.block-contact {
  color: white;
  margin-bottom: 36px;

  .block-contact-title {
    color: $gray-darker;
  }

  a {
    color: $footer-contact-link;

    &:hover {
      text-decoration: underline;
    }
  }

  .store-info-title {
    display: block;
  }

  .website {
    margin-top: 29px;
    display: block;
  }

  .store-info {
    line-height: rem(19px);
  }

  .footer-border {
    padding-bottom: 41px;
  }
}

.linklist {
  .blockcms-title a {
    color: $gray-darker;
  }
}

.account-list a {
  color: $gray;

  &:hover {
    color: $brand-primary;
  }
}

.blockcms-title,
.myaccount-title,
.myaccount-title a,
.block-contact-title {
  font-weight: 700;
  font-size: $base-font-size;
}

#consumer-website {
  display: block;
  margin-top: 18px;
}

.block-social {
  text-align: center;

  ul {
    margin-bottom: rem(35px);

    li {
      height: rem(34px);
      width: rem(34px);
      background-color: white;
      background-repeat: no-repeat;
      display: inline-block;
      margin: 0 rem(10px);
      cursor: pointer;
      border-radius: 50%;

      &:hover {
        background-color: $crd-secondary-color;
      }

      a {
        display: block;
        font-size: rem(22px);
        line-height: rem(28px);
        white-space: nowrap;
        overflow: hidden;
        color: $brand-primary;

        i {
          opacity: 1;
          background-color: #333;
          border-radius: 30px;
          color: #fff;
          display: inline-block;
          height: 25px;
          line-height: 30px;
          margin: auto 3px;
          width: 25px;
          font-size: 15px;
          text-align: center;
        }

      }
    }
  }

  .fa:before {
    position: relative;
    top: -2px;
  }
}

.fa-tiktok {
  background-image: url(../img/tiktok.svg);
}
.facebook {
  background-image: url(../img/facebook.svg);

  &::before {
    content: "";
    background-image: url(../img/facebook-blue.svg);
  }

  &.icon-gray {
    background-image: url(../img/facebook-gray.svg);

    &:hover {
      background-image: url(../img/facebook-blue.svg);
    }
  }
}

.twitter {
  background-image: url(../img/twitter.svg);

  &::before {
    content: "";
    background-image: url(../img/twitter-blue.svg);
  }

  &.icon-gray {
    background-image: url(../img/twitter-gray.svg);

    &:hover {
      background-image: url(../img/twitter-blue.svg);
    }
  }
}

.rss {
  background-image: url(../img/rss.svg);
}

.youtube {
  background-image: url(../img/youtube.svg);
}

.googleplus {
  background-image: url(../img/gplus.svg);

  &::before {
    content: "";
    background-image: url(../img/gplus-blue.svg);
  }

  &.icon-gray {
    background-image: url(../img/gplus-gray.svg);

    &:hover {
      background-image: url(../img/gplus-blue.svg);
    }
  }
}

#block_myaccount_infos {
  .myaccount-title a {
    color: $gray-darker;
  }
}

.pinterest {
  background-image: url(../img/pinterest.svg);

  &::before {
    content: "";
    background-image: url(../img/pinterest-blue.svg);
  }

  &.icon-gray {
    background-image: url(../img/pinterest-gray.svg);

    &:hover {
      background-image: url(../img/pinterest-blue.svg);
    }
  }
}

.vimeo {
  background-image: url(../img/vimeo.svg);
}

.instagram {
  background-image: url(../img/instagram.svg);
}

.footer-container {
  padding-top: rem(59px);
  overflow: hidden;

  .footer-popup {
    @media (min-height: 900px) {
      position: fixed;
      top: 100px;
      bottom: 0;
    }
  }

  .footer-links {
    .col-md-3.wrapper {
      .h3.hidden-sm-down {
        color: rgb(89, 89, 89);
        font-size: 14px;
        font-weight: 700;
        font-style: normal;
        letter-spacing: 0px;
        text-align: left;
        line-height: 22px;
      }

      li a {
        color: rgb(89, 89, 89);
        font-size: 13px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 0px;
        text-align: left;
        line-height: 20px;
      }
    }
  }

  li {
    margin-bottom: 0;
    line-height: 15px;
  }

  li a {
    color: $gray-dark;
    cursor: pointer;
    font-size: 12px;

    &:hover {
      color: $brand-primary;
    }

    &:focus {
      text-decoration: none;
    }
  }

  .links {
    h3 {
      display: none;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding-bottom: 29px;
      margin-bottom: 43px;
    }

    li {
      width: inherit;

      a {
        color: $cornflower-blue;

        &:hover {
          color: white;
        }

        &:focus {
          text-decoration: none;
        }
      }

      .current-page {
        color: white;
        text-decoration: underline;
      }
    }
  }

  .footer-border {
    border-bottom: 1px solid $footer-border;
  }

  .col-sm-3, .footer-container .col-sm-4 {
    text-align: center;
  }

  .block-contact .padded-left {
    line-height: 18px;
    display: inline-block;
    text-align: left;

    span {
      display: block;
    }
  }
}

div.aeuc_footer_info {
  margin: 0;
}

.copyright {
  margin-bottom: 53px;
  text-align: center;
  color: white;

  span {
    color: rgb(89, 89, 89);
    font-size: 11px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0px;
    text-align: left;
    line-height: 16px;
  }

  a {
    color: white;
  }
}

@include media-breakpoint-down(sm) {
  .block_newsletter {
    //padding-bottom: rem(10px);
  }
  .footer-container {
    box-shadow: none;
    margin-top: 0;
    padding-top: 20px;

    .links {
      .h3 {
        line-height: 1.5;
        font-size: rem(16px);
        color: white;
      }

      .title {
        padding: rem(10px);
        cursor: pointer;
        border-bottom: 1px solid RGB(216, 215, 223);

        &[aria-expanded="true"] {
          border-bottom: none;
        }

        .collapse-icons .remove {
          display: none;
        }
      }

      .title[aria-expanded="true"] .collapse-icons {
        .add {
          display: none;
        }

        .remove {
          display: block;
        }
      }

      .navbar-toggler {
        display: inline-block;
        padding: 0;
      }

      .collapse {
        display: none;

        &.in {
          display: block;
        }
      }

      ul {
        margin-bottom: 0;
        display: block;
        border-bottom: 1px solid RGB(216, 215, 223);

        &.collapse.show {
          padding-bottom: 10px;
        }

        > li {
          padding: 6px;
          font-weight: bold;
          background: #fff;
          margin: 0;

          a {
            color: $brand-primary;

            &:hover {
              color: $crd-secondary-color;
              text-decoration: none;
            }
          }
        }
      }

      .material-icons {
        color: white;
      }
    }

    .store-info-title {
      margin-top: 20px;
    }

    .website {
      margin: 20px 0;
    }

    .block-contact {
      .store-info-title {
        margin-bottom: 0;
      }
    }
  }

  .footer-container .col-sm-3, .footer-container .col-sm-4 {
    text-align: left;
  }
}

@include media-breakpoint-down(md) {
  .block-social {
    text-align: center;
  }
  .block-contact {
    padding-left: rem(24px);
  }
  .footer-container .links ul.footer-border > li a.current-page {
    color: white;
  }
}

@include media-breakpoint-down(xs) {
  .block_newsletter {
    .input-group {
      width: 100%;
    }
  }
}

@media (min-width: 550px) and (max-width: 767px) {
  .footer-container {
    .store-info-title {
      margin-top: 0;
    }
  }
}
