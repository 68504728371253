.form-group {
  margin-bottom: 5px;
  .form-control {
    height: 34px;
    padding: 7px 14px 7px 8px;
    line-height: 17px;
    border: 1px solid $input-border;
    background: #fff;
    color: #8b8b8b;
    font-family: GalaxiePolarisBook, sans-serif;
    font-size: 12px;
    font-weight: 700;
    border-radius: 5px;
  }
  .form-control:focus, .input-group.focus {
    outline: none;
  }
  .label, label {
    color: #222;
    font-family: GalaxiePolarisBook, sans-serif;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    &.required {
      color: $crd-primary-color;
      &:before {
        content: '*';
      }
    }
  }
  input[type="file"] {
    max-width: 100%;
  }
}

form {
  .forgot-password {
    margin-bottom: 10px;
    a:focus {
      text-decoration: none;
    }
  }
}
.row-form{
  display: flex;
  flex-wrap: wrap;
}

#authentication [data-action=show-password] {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.show-password-btn {
  background-color: transparent;
  border-color: transparent;
  position: absolute;
  right: 6px;
  top: 2px;
  padding: 0;
  line-height: initial;
  height: initial;
  font-size: 17px;
  &:focus {
    outline: none;
  }
}

.input-group .input-group-btn > .show-password-btn {
  font-size: 17px;
}

select.form-control:not([size]):not([multiple]) {
  height: 34px;
}

.form-group {
  .show-psw {
    position: relative;
    input.form-control {
      padding: 7px 26px 7px 14px;
    }
  }
  .errors {
    color: #ff4c4c;
  }
  &.has-error {
    input {
      box-shadow: 0 0 3pt 2pt #ff4c4c;
      outline: none;
    }
  }
}

form {
  .form-group {
    &.has-error {
      input {
        box-shadow: 0 0 3pt 2pt #ff4c4c;
        outline: none;
      }
    }
  }
}

.form-control:focus {
  box-shadow: none;
}

.input-group-addon, .input-group-btn, .input-group .form-control {
  display: table-cell;
}

.input-group .form-control {
  position: relative;
  z-index: 2;
  float: left;
  width: 100%;
  margin-bottom: 0;
}

.input-group-addon, .input-group-btn {
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
}

.input-group-addon, .input-group-btn {
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
}

.btn{
  line-height: 1.25;
}

.input-group-btn {
  font-size: 0;
  white-space: nowrap;
}