/** BUTTONS **/
.btn-primary {
  background-color: $crd-primary-color;
  border-color: transparent;
  border-radius: 19px;
  line-height: 39px !important;
  height: 39px;

  &:hover {
    background-color: $crd-secondary-color;
  }
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle,
.btn-primary:not(:disabled):not(.disabled):active:focus {
  border-color: transparent;
  background-color: darken($crd-primary-color, 17%);
  box-shadow: none;
}

@mixin vertical-align($position: relative) {
  position: $position;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

// Responsive visibility utilities

@each $bp in map-keys($grid-breakpoints) {
  .hidden-#{$bp}-up {
    @include media-breakpoint-up($bp) {
      display: none !important;
    }
  }
  .hidden-#{$bp}-down {
    @include media-breakpoint-down($bp) {
      display: none !important;
    }
  }
}
