#password {
  #main {
    background: none;
    max-width: 530px;
    margin: 150px auto;

    h1 {
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 100px;
      font-weight: normal;
      padding: 0;
    }
  }

  .form-fields {
    input {
      margin-bottom: 24px;
      color: $gray;
    }

    label {
      padding: 0;
      margin: 0 0 5px 0;
      color: #A0A0A0;;
    }
  }

  .btn:not(.btn-nsl) {
    margin-top: 25px;
    text-transform: none;
    padding: 0 29px;
    height: 51px;
    border-radius: 30px;
    font-size: 20px;
    font-weight: normal;

    .fa {
      margin-right: 10px;
    }
  }

  span.password-requirements {
    color: #FF0000FF;
  }

  ul.password-requirements-list {
    list-style: circle;
    padding-left: 40px;
    color: #FF0000FF;
  }
}

@include media-breakpoint-down(sm) {
  #password {
    #wrapper {
      background: #e5e5e5;
    }

    #main {
      margin: 28px 0px;
      max-width: none;
      padding: 0 15px;

      h1 {
        margin-bottom: 60px;
        font-size: 21px;
      }
    }

    .btn {
      margin-top: 70px;
    }
  }
}
