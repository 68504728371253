#custom-text {
  background: white;
  border-radius: 2px;
  margin-bottom: rem(24px);
  padding: rem(80px) 0;
  text-align: center;
  h3 {
    text-transform: uppercase;
    color: $lochmara;
    font-weight: 700;
    margin: rem(24px) 0 rem(12px);
    font-family: GalaxiePolarisBook, sans-serif;
  }
  p {
    color: $gray-darker;
    font-weight: 400;
    font-size: 1.1em;
    .dark {
      color: $gray;
      font-weight: 400;
    }
  }
  img {
    max-width: 100%;
    height: auto;
  }
  .icon {
    width: 68px;
    height: 68px;
    border: 1px solid $gainsboro;
    border-radius: 50%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    position: relative;

    img {
      display: inline-block;
      align-self: center;
    }
  }
  .banner-section {
    margin: rem(100px) 0 0;
    a {
      overflow: hidden;
      max-width: 100%;
      display: block;
      border: 1px solid $gray-light;
      img {
        transition: all 0.2s ease 0s;
      }
      &:hover {
        img {
          transform: scale(1.1);
          cursor: pointer;
        }
      }
    }
    &.no-banners {
      a {
        background: #3cb7e0;
        color: white;
        font-family: $headingFontFamily;
        font-size: 24px;
        height: 85px;
        text-align: right;
        padding: 49px 15px 14px;
        &:hover {
          background: #003B5C;
        }
      }
    }
  }
  &.no-links {
    a {
      cursor: default;
      pointer-events: none;
    }
  }
  .connector {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      height: 1px;
      border-top: 1px dashed $very-light-grey;
      top: 50%;
      left: calc(50% + 34px + 50px);
      width: calc(100% - 34px - 100px);
    }
  }
}

.page-content.page-cms {
  background: white;
  padding: rem(20px);
  text-align: justify;
  .cms-box {
    img {
      max-width: 100%;
    }
  }
}

@include media-breakpoint-down(md) {
  #block-cmsinfo {
    padding: $medium-space $large-space;
  }
  #custom-text h3.black-font {
    margin-bottom: 40px;
  }
  #custom-text.container {
    padding: 30px 15px;
  }
}

@include media-breakpoint-down(sm) {
  #custom-text {
    padding: 30px;
    .banner-section {
      margin: 20px 0;
      a {
        margin: 10px 0;
      }
    }
    .connector {
      &:after {
        content: none;
        display: none;
      }
    }
    .icons-section {
      h6 {
        margin-bottom: 45px;
      }
      h3 {
        margin-top: 16px;
      }
    }
  }
}

