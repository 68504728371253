#product {
  #content {
    position: relative;
    max-width: 555px;
    margin: 0 auto;
  }

  h1 {
    font-family: $mainFontFamily;
    color: $prod-title;
    text-transform: none;
  }

  .expand {
    position: absolute;
    right: 22px;
    bottom: 19px;
    font-size: rem(24px);
    color: $gray;
    width: 39px;
    height: 39px;
    line-height: 30px;
    background: white;
    text-align: center;
    border-radius: 50%;
    border: 1px solid #e7e7e7;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
    cursor: pointer;

    &:hover {
      color: #00A4E7;

      .expand-icon {
        background: url(../img/FullScreen-blue.svg) center no-repeat;
        background-size: 21px 21px;
      }
    }

    .expand-icon {
      background: url(../img/FullScreen.svg) center no-repeat;
      background-size: 21px 21px;
      width: 100%;
      display: block;
      height: 100%;
    }
  }

  .available-status {
    color: $crd-secondary-color;
  }

  .product-information span {
    font-size: $base-font-size;
  }

  .prod-desc-short {
    overfaow: hidden;

    p:after {
      content: '...';
    }
  }

  #goToDescription {
    background: none;
    border: none;
    padding: 0;
    color: $crd-primary-color;

    &:hover, &:focus {
      color: $crd-secondary-color;
      outline: none;
    }
  }

  .social-sharing {
    position: absolute;
    bottom: -4px;
    width: inherit;
  }

  .social-btns-position {
    position: relative;
    min-height: 460px;
    padding-bottom: 55px;

    .customer-addon {
      float: left;
      margin-top: 0;
    }

    .product-prices {
      float: left;
      margin-top: 0;
    }

    .product-add-to-cart {
      margin-top: 28px;
      margin-bottom: 0;
    }
  }

  .current-price {
    font-family: GalaxiePolarisBook, sans-serif;

    .regular-price {
      font-size: 15px;
      color: #8b8b8b;
      text-decoration: line-through;
    }

    .base-price {
      font-size: 14px;
      line-height: 14px;
      color: $gray-dark;
      font-weight: normal;
    }
  }

  .customer-addon {
    .add-to-fav {
      position: static;
      margin-bottom: 16px;
    }
  }

  .curaprox-links {
    margin: 10px 0;
  }

  .curaprox-links {
    margin: 10px 0;
  }

  .at-style-responsive {
    .at-share-btn {
      margin-right: 18px;

      &:hover {
        transform: none;
        -webkit-transform: none;
        -moz-transform: none;
      }
    }

    span.at-icon-wrapper {
      width: 24px !important;
      height: 24px !important;

      svg.at-icon {
        width: 24px !important;
        height: 24px !important;
      }
    }
  }

  .login-link {
    padding: 10px 0 15px;
    display: inline-block;
    font-size: 15px;
  }
}

.product-price {
  color: $prod-price;
  display: inline-block;

  .current-price {
    font-size: 28px;
    font-family: GalaxiePolarisBook, sans-serif;
    font-weight: 700;
  }
}

[id^="product-description-short-"] {
  margin-top: 25px;
  line-height: rem(20px);
}

.product-information {
  font-size: $font-size-lg;
  color: $gray-dark;

  .manufacturer-logo {
    height: 35px;
  }

  .product-add-to-cart .input-box {
    max-width: none;
  }

  .product-quantity .qty {
    max-width: none;
    width: 95px;
  }
}

.input-color {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: rem(20px);
  width: rem(20px);
}

.input-container {
  position: relative;
}

.input-radio {
  position: absolute;
  top: 0;
  cursor: pointer;
  opacity: 0;
  width: 100%;
  height: 100%;
}

.input-color,
.input-radio {
  &:checked + span,
  &:hover + span {
    border: 2px solid $gray-darker;
  }
}

.radio-label {
  @include box-shadow;
  background: white;
  display: inline-block;
  padding: rem(2px) $small-space;
  font-weight: 600;
  border: 2px solid white;
}

.product-actions {
  .control-label {
    width: 100%;
    margin-bottom: rem(6px);
    display: block;
    width: 100%;
  }
}

.input-box .value-button {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                   supported by Chrome and Opera */
  .material-icons {
    font-weight: bold;
  }
}

.product-add-to-cart {
  .input-box {
    position: relative;
    max-width: 87px;
    width: 100%;
  }

  .qty {
    margin-right: 13px;
    width: 100%;
    max-width: 87px;
  }

  .qty .value-button {
    display: block;
    position: absolute;
    left: 9px;
    top: 10px;
    color: #939393;
    cursor: pointer;
  }

  .qty .material-icons {
    font-size: rem(18px);
  }

  .qty input.qty-input {
    display: block;
    text-align: center;
    border: 1px solid #e6e6e6;
    border-radius: 19px;
    margin: 0;
    width: 100%;
    height: 39px;
    padding: 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 39px;
  }

  .value-button.increase {
    right: 9px;
    left: auto;
  }

  margin-top: 38px;
}

.product-quantity {
  .qty {
    float: left;
    width: 80px;
    margin-bottom: rem(8px);
  }

  .add {
    float: left;
    margin-bottom: rem(8px);
  }

  #quantity_wanted {
    color: $gray-darker;
    background-color: white;
    height: rem(44px);
    padding: 0.175rem 0.5rem;
    width: rem(48px);
  }

  .input-group-btn-vertical {
    float: left;
  }

  .input-group-btn-vertical {
    .btn {
      padding: rem(8px) rem(11px);

      i {
        font-size: rem(16px);
        top: rem(2px);
        left: rem(3px);
      }
    }
  }

  .btn-touchspin {
    height: rem(23px);
  }
}

.product-discounts {
  > .product-discounts-title {
    font-weight: normal;
    font-size: $font-size-sm;
  }

  > .table-product-discounts {
    thead tr th {
      width: 33%;
      padding: $small-space $medium-space;
      background: white;
      border: rem(5px) $gray-light solid;
      text-align: center;
    }

    tbody tr {
      background: $gray-lighter;

      &:nth-of-type(even) {
        background: white;
      }

      td {
        padding: $small-space $medium-space;
        text-align: center;
        border: rem(5px) $gray-light solid;
      }
    }
  }
}

.product-prices {
  margin-top: $medium-space;

  .tax-shipping-delivery-label {
    font-size: rem(13px);
    color: $gray-dark;
    display: none;
  }

  .product-price {
    margin-bottom: 21px;
  }

  .price-tax {
    color: $gray-dark;
    margin-top: 12px;
    font-size: 13px;
    font-weight: 400;
  }
}

.product-discount {
  color: $gray;

  .regular-price {
    text-decoration: line-through;
    font-weight: normal;
    margin-right: $small-space;
  }
}

.has-discount {
  &.product-price, p {
    color: $brand-secondary;
  }

  .discount {
    background: $brand-secondary;
    color: white;
    font-weight: 600;
    padding: rem(5px) $small-space;
    font-size: $font-size-base;
    @media (max-width: 500px) {
      padding: rem(4px) $small-space;
      font-size: 0.8rem;
    }
    margin-left: $small-space;
    text-transform: uppercase;
    display: inline-block;
  }
}

.product-unit-price {
  font-size: $font-size-xs;
  margin-bottom: 0;
}

.tabs {
  margin-top: rem(32px);

  .tab-content {
    background: $white-smoke;
    border: 1px solid $gainsboro;
    padding: 29px 23px;

    .attachment {
      h4 {
        margin: 10px 0;

        img {
          max-width: 100%;
        }
      }
    }
  }

  .tab-pane {
    font-weight: bold;

    .youtube-video {
      padding-bottom: 29px;

      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  .nav-tabs {
    border: none;
    margin-bottom: 9px;

    .nav-link {
      color: $brand-primary-title;
      background: $white-smoke;
      border: 0 solid transparent;
      text-transform: uppercase;
      padding: 13px 5px;
      min-width: 150px;
      text-align: center;
      line-height: 14px;
      letter-spacing: 1px;

      &.active {
        background: $crd-secondary-color;
        border: none;
        color: white;

        img {
          display: none;
        }

        img.hover {
          display: inline-block;
        }
      }

      &:hover {
        border: none;
      }

      img {
        width: 25px;
        height: 25px;
      }

      .hover {
        display: none;
      }
    }

    .nav-item {
      float: left;
      margin-right: 10px;
      margin-left: 0;
      margin-bottom: 0;
    }
  }

  .product-description {
    line-height: 25px;
    font-weight: normal;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;

    &.show {
      -webkit-line-clamp: inherit;
      overflow: auto;
    }
  }
}

.product-cover {
  margin-bottom: $medium-space;
  position: relative;

  img {
    background: white;
    border-radius: 10px;
    max-width: 100%;
    padding: 20px;
  }

  .layer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: white;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    background: rgba(255, 255, 255, 0.7);
    text-align: center;
    cursor: pointer;
    @include transition(opacity .7s ease-in-out);

    &:hover {
      opacity: 1;
    }

    .zoom-in {
      font-size: rem(100px);
      color: white;
    }
  }
}

#product-modal {
  padding: 0;

  .modal-content {
    background: transparent;
    border: none;
    padding: 0;
    width: 100%;

    .modal-body {
      display: flex;
      //margin-left: -30%;

      .product-cover-modal {
        background: white;
        border-radius: 10px;
      }

      .image-caption {
        background: white;
        width: 800px;
        padding: rem(10px) rem(20px);
        border-top: $gray-light 1px solid;

        p {
          margin-bottom: 0;
        }
      }

      .thumbnails {
        position: relative;
      }

      .mask {
        position: relative;
        overflow: hidden;
        z-index: 1;
        margin-top: 0;
        max-height: 807px;

        &.nomargin {
          margin-top: 0;
        }
      }

      .product-images {
        margin-left: 14px;

        li.thumb-container {
          margin-bottom: 14px;
          display: block;
        }

        img {
          width: 147px;
          cursor: pointer;
          background: white;
          border-radius: 10px;

          &:hover {
            border: $brand-primary 3px solid;
          }
        }
      }

      .arrows {
        height: 100%;
        width: 100%;
        text-align: center;
        position: absolute;
        top: 0;
        color: white;
        right: $large-space;
        z-index: 0;
        left: 14px;
        max-width: 147px;
        max-height: 799px;

        .arrow-up {
          position: absolute;
          top: -35px;
          font-size: 25px;
          left: 50%;
          transform: translateX(-13px);
          opacity: 0.2;
        }

        .arrow-down {
          position: absolute;
          bottom: -35px;
          font-size: 25px;
          left: 50%;
          transform: translateX(-13px);
        }

        i {
          font-size: rem(100px);
          display: inline;
        }

        cursor: pointer;
      }
    }
  }
}

#blockcart-modal {
  color: $gray-darker;

  .modal-header {
    background: $brand-success;
  }

  .modal-body {
    background: $gray-light;
    padding: rem(50px) rem(30px);

    .divide-right span {
      display: inline-block;
      margin-bottom: rem(5px);
    }
  }

  .modal-dialog {
    max-width: 1140px;
    width: 100%;
  }

  .product-image {
    width: rem(210px);
  }

  .modal-title {
    font-weight: 600;
    color: white;
    font-size: rem(18px);

    i.material-icons {
      margin-right: $large-space;
    }
  }

  .product-name {
    color: $brand-primary;
    font-size: rem(18px);
  }

  .cart-products-count {
    font-size: rem(18px);
  }

  .cart-content {
    padding-left: $extra-large-space;

    button {
      margin-right: rem(5px);
    }
  }

  .divide-right {
    border-right: $gray 1px solid;
  }
}

.product-images {
  margin: 0;
  touch-action: manipulation;

  > li.thumb-container {
    display: inline;

    > .product-cover .thumb {
      @include box-shadow;
      cursor: pointer;

      .images-container & {
      }

      .thumb {
        max-width: 100%;
      }
    }

    img {
      vertical-align: inherit;
    }
  }
}

#main {
  .images-container {
    .js-qv-mask {
      white-space: nowrap;
      overflow: hidden;
      border: 1px solid #dadada;
      border-radius: 10px;

      &.scroll {
        margin: 0 auto;
        border: 1px solid $gainsboro;
        border-radius: 10px;
      }
    }
  }
}

.scroll-box-arrows {
  display: none;

  &.scroll {
    display: block;
  }

  i {
    position: absolute;
    bottom: rem(26px);
    line-height: 30px;
    cursor: pointer;
    color: #fff;
    background: #d9d9d9;
    width: 29px;
    height: 29px;
    text-align: center;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-14px);
  }

  .left {
    left: 15px;
  }

  .right {
    right: 15px;
    padding-left: 3px;
  }
}

#product-availability {
  display: inline-block;
  font-weight: 700;
  margin-left: $small-space;

  .material-icons {
    line-height: inherit;
  }

  .product-available {
    color: $brand-success;
  }

  .product-unavailable {
    color: $brand-warning;
  }

  .product-last-items {
    color: $brand-warning;
  }
}

#product-details {
  .label {
    font-size: $base-font-size;
    color: $gray-dark;
  }
}

.product-features {
  > dl.data-sheet {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    dd.value,
    dt.name {
      flex: 1 0 40%;
      font-weight: normal;
      background: $gray-light;
      padding: $small-space;
      margin-right: $small-space;
      min-height: rem(40px);
      word-break: break-all;

      &:nth-of-type(even) {
        background: $gray-lighter;
      }
    }

    dt.name {
      text-transform: capitalize;
    }
  }
}

.product-variants {
  > .product-variants-item {
    margin: $medium-space 0;

    select {
      border: 1px solid $gray-light;
      height: rem(40px);
      width: rem(60px);
    }

    ul li {
      margin-right: $small-space;
    }

    .color {
      margin-left: 0;
      margin-top: 0;
    }
  }
}

.product-flags {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

li.product-flag {
  display: block;
  position: absolute;
  background: $brand-primary;
  font-weight: 700;
  padding: rem(5px) rem(7px);
  text-transform: uppercase;
  color: white;
  margin-top: $small-space;
  margin-left: rem(-5px);
  z-index: 2;

  &.online-only {
    top: rem(400px);
    right: 0;
    font-size: $font-size-xs;

    &::before {
      content: "\E30A";
      font-family: 'Material Icons';
      vertical-align: middle;
      margin: rem(5px);
    }
  }

  &.discount {
    display: none;
  }

  &.on-sale {
    background: $brand-secondary;
    width: 100%;
    text-align: center;
    margin: 0;
    left: 0;
    top: 0;
  }
}

.product-customization {
  @include box-shadow;
  margin: $extra-large-space 0;

  .product-customization-item {
    margin: $medium-space 0;
  }

  .product-message {
    background: $gray-light;
    border: none;
    width: 100%;
    height: rem(50px);
    resize: none;
    padding: rem(10px);

    input::placeholder {
      color: $gray;
    }
  }

  .file-input {
    width: 100%;
    opacity: 0;
    left: 0;
    z-index: 1;
    cursor: pointer;
    height: rem(42px);
    overflow: hidden;
    position: absolute;
  }

  .custom-file {
    position: relative;
    background: $gray-light;
    width: 100%;
    height: rem(42px);
    line-height: rem(42px);
    text-indent: rem(10px);
    display: block;
    color: $gray;
    margin-top: $medium-space;

    button {
      z-index: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  small {
    color: $gray;
  }
}

.product-pack {
  margin-top: $extra-large-space;

  .pack-product-container {
    display: flex;
    justify-content: space-around;

    .pack-product-name {
      flex: 0 0 50%;
      font-size: rem(14px);
      color: $gray;
    }

    .pack-product-quantity {
      border-left: $gray-light 2px solid;
      padding-left: $small-space;
    }

    .pack-product-name,
    .pack-product-price,
    .pack-product-quantity {
      display: flex;
      align-items: center;
    }
  }
}

.product-refresh {
  margin-top: $medium-space;
}

.social-sharing {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: rem(24px);

  ul {
    margin-bottom: 0;
  }

  li {
    @include box-shadow;
    height: rem(40px);
    width: rem(40px);
    border-radius: 50%;
    display: inline-block;
    background-color: white;
    background-repeat: no-repeat;
    background-size: rem(32px) rem(32px);
    background-position: rem(4px) rem(4px);
    cursor: pointer;
    margin-left: rem(8px);
    @include transition(all .2s ease-in);

    a {
      display: block;
      width: 100%;
      height: 100%;
      white-space: nowrap;
      text-indent: 100%;
      overflow: hidden;

      &:hover {
        color: transparent;
      }
    }
  }
}

.products-selection {
  margin-bottom: $medium-space;

  .title {
    color: $gray-dark;
  }
}

#blockcart-modal .cart-content {
  .btn {
    margin-bottom: $small-space;
  }
}

.products-in-category {
  .title {
    margin: 80px 0 50px;
    font-family: $headingFontFamily;
    text-transform: uppercase;
    font-weight: normal;
    color: #231f20;
  }
}

.product-price-and-shipping {
  .add-to-fav {
    display: inline-block;
    float: right;
    position: static;
  }

  .history-icon {
    display: inline-block;
    float: right;
    margin: 0 10px;
    position: static;
  }
}

#product-badge-container {
  margin-top: 15px;
}

#product-badge {
  max-width: 482px;
  position: relative;
  color: $prod-badge;

  .logo {
    position: relative;
    z-index: 1;
    cursor: pointer;
    border-radius: 50%;

    img {
      max-height: 100px;
    }
  }

  .content {
    max-height: 88px;
    overflow: hidden;
    width: 340px;
    position: absolute;
    left: 59px;
    border: 1px solid $prod-badge;
    border-radius: 17px;
    top: 7px;
    padding: 6px 10px 6px 45px;

    p {
      color: #3cb7e0;
      margin: 0;
      font-size: 10px;
      line-height: 12px;
    }

    .btn {
      font-size: 10px;
      color: #fff;
      background: $prod-badge;
      border-radius: 17px;
      padding: 2px 12px 4px;
      margin-top: 5px;
      line-height: inherit;
    }

    h3 {
      font-size: 11px;
      margin-bottom: 0;
      color: $prod-badge;
    }
  }
}

.thumbnail-container {
  .btn-primary {
    font-size: 12px;
  }
}

/* Second style for badge */
a.badge-second {
  outline: none;
  position: absolute;
  width: 80px;
  height: 80px;
  top: 10px;
  right: 10px;
  z-index: 1;
  display: block;
}

#badgeSecondModal {
  .modal-header {
    border-bottom: none;
    padding-top: 23px;

    .modal-title {
      color: $crd-secondary-color;
    }

    button.close {
      margin-top: -16px;
    }
  }

  .modal-content {
    max-width: 500px;
    border-radius: 15px;

    .modal-body {
      padding: 0 45px 8px;
      line-height: 24px;
    }
  }

  a.btn {
    background: $crd-primary-color;
    color: #fff;
    border-radius: 19px;
    line-height: 18px;
    font-family: GalaxiePolarisBook, sans-serif;
    font-size: 13px;

    &:hover {
      background: $crd-secondary-color;
    }
  }
}

.lnh {
  line-height: 1.25rem;
}

.btn-primary.disabled, .btn-primary:disabled {
  background: $crd-primary-color;
}

#products {
  .product-miniature.erp_promoted {
    .thumbnail-container {
      border: 1px solid $crd-secondary-color;

      .btn-primary {
        background: $crd-secondary-color;

        &:hover {
          background: $crd-primary-color;
        }
      }

      .add-cart-redesign .cart-button {
        border-color: darken($crd-secondary-color, 5);
      }

      .add-cart-redesign .cart-button:hover {
        border-color: darken($crd-primary-color, 5);
      }
    }
  }
}

.request-price-btn {
  font-size: 17px;
  font-weight: 400;
  border-radius: 26px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  padding: 10px 35px 12px;
  line-height: 17px;

  .fa {
    height: 18px;
    width: 18px;
    margin-right: 17px;
  }
}

@include media-breakpoint-up(md) {
  #product-modal .modal-content .modal-body {
    .product-images img {
      padding: 10px;
    }

    .product-cover-modal {
      padding: 30px;
    }
  }
}

@include media-breakpoint-down(lg) {
  .products-in-category {
    .title {
      margin: 20px 0 40px;
    }
  }
  #product-modal {
    .modal-content {
      .modal-body {
        .arrows {
          max-width: 100px;
          display: block;
        }

        .mask {
          max-height: 562px;
        }

        .product-images {
          max-width: 150px;

          img {
            width: 98px;
          }
        }
      }

      .modal-body {
        flex-direction: row;
        margin-left: 0;

        img.product-cover-modal {
          width: 100%;
        }
      }
    }

    figure {
      margin: 0;
      max-width: 558px;
      flex-basis: auto;
      flex-grow: 1;
    }

    .modal-dialog {
      max-width: 700px;
    }
  }
  #product {
    .product-add-to-cart {
      margin-top: 15px;
      margin-bottom: 3px;
    }

    .social-btns-position {
      height: inherit;
      min-height: 0;
      padding-bottom: 37px;
    }
  }
  #product-badge {
    margin-bottom: 17px;

    .content {
      max-width: calc(100% - 100px);

      p {
        font-size: 10px;
        line-height: 11px;
      }

      h3 {
        font-size: 10px;
      }

      .btn {
        font-size: 10px;
        padding: 0 11px 3px;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .products-in-category {
    .pull-left, .pull-right {
      float: none;
      text-align: center;
    }

    .qty-box {
      .input-box {
        margin: 0;
      }
    }

    .product-description {
      .product-price-and-shipping {
        text-align: center;
      }

      h1.product-title {
        text-align: center;
      }
    }
  }
  .product-miniature .product-description, .products-in-category .product-description {
    padding: 0 3px;
  }
}

@include media-breakpoint-down(md) {
  .product-cover {
    img {
      width: 100%;
    }
  }
  #product-modal .modal-content .modal-body .image-caption {
    width: 100%;
  }
  #blockcart-modal {
    .modal-dialog {
      width: calc(100% - 20px);
    }

    .modal-body {
      padding: rem(30px);
    }
  }
  .product-minimal-quantity {
    margin: 0;
  }
  #product {
    h1 {
      margin-top: 20px;
    }

    .tabs .nav-tabs .nav-item {
      margin-top: 10px;
    }
  }
  #badgeSecondModal {
    padding-left: 15px;
    padding-right: 15px;

    .modal-content {
      width: 100%;

      .modal-body {
        padding: 0 13px 8px;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  #blockcart-modal {
    .divide-right {
      border-right: none;
    }

    .modal-body {
      padding: rem(16px);
    }
  }
  #product {
    .curaprox-links {
      position: initial;
      transform: initial;
      margin-top: 10px;
    }

    .social-btns-position .h1 {
      margin-top: 20px;
    }

    .tabs .nav-tabs .nav-link {
      padding: 6px 8px;
      width: inherit;
      min-width: 60px;

      .fa {
        font-size: 22px;
      }
    }
  }
  #product-modal {
    .modal-content {
      .modal-body {
        .product-images img {
          width: 41px;
        }

        .arrows {
          display: none;
        }
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  #product .social-btns-position {
    height: inherit;
    min-height: 0;
  }
  #products {
    .product-miniature {
      .product-description {
        .pull-right, .pull-left {
          float: none;
          text-align: center;
        }

        .input-box {
          margin: 0 auto 12px;
        }
      }
    }
  }
  #product-modal {
    figure {
      max-width: 556px;
    }

    .modal-dialog {
      max-width: 829px;
    }

    .modal-content {
      .modal-body {
        .mask {
          max-height: 562px;
        }
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  #product-badge {
    .logo {
      img {
        max-height: 101px;
      }
    }

    .content {
      padding: 10px 20px;
      max-width: 100%;
      width: 100%;
      position: relative;
      top: 0;
      left: 0;
      max-height: none;
      margin-top: 10px;

      p {
        font-size: 10px;
      }

      h3 {
        font-size: 10px;
      }

      .btn {
        font-size: 9px;
        padding: 3px 11px;
      }
    }
  }
  .product-miniature {
    .product-thumbnail {
      text-align: center;
    }

    img {
      max-height: 343px;
      width: auto;
    }
  }
  #js-product-list {
    .up {
      position: absolute;
      right: 15px;
    }
  }
}

.product-miniature {
  .not-allowed-to-buy {
    width: 100%;
    height: 40px;
    text-transform: none;
    line-height: 37px !important;
    border: 1px;
    border-radius: 40px;
    font-weight: 700;
  }
}
