#block-favorites,
#block-history {
  #js-product-list {
    .products-flex {
      @media (min-width: 1081px) {
        @include flexboxGridMixin(3, 20, 20);
      }
      @media (max-width: 1080px) and (min-width: 601px) {
        @include flexboxGridMixin(2, 20, 20);
      }
      @media (max-width: 600px) {
        @include flexboxGridMixin(1, 0, 20);
      }
    }
  }
}

#removeFavorites, #historyModal {
  z-index: 10000;

  .modal-dialog {
    width: 480px;
    text-align: center;

    p {
      margin: 20px;
      font-size: 16px;
    }

    .btn {
      margin: 0 25px;
      width: 120px;
    }

    .btn-primary.btn-danger {
      background-color: #dc3545;
    }
  }

  .modal-footer {
    border: none;
  }
}

@media (max-width: 767px) {
  #removeFavorites, #historyModal {
    .center-modal {
      display: flex;
      align-items: center;
      height: 100%;
    }

    padding: 0 10px;

    .modal-dialog {
      margin: 0 auto;

      .btn {
        margin: 0 12px;
      }
    }
  }
}
