.lock-scroll {
  position: fixed;
}

#mobile_top_menu_wrapper {
  position: fixed;
  left: -5000px;
  width: calc(100% - 45px);
  max-width: 400px;
  margin-top: 0;
  z-index: 10000;
  height: 100vh;
  padding: 0;
  color: $nav-background-color;
  background: $nav-links-color;
  overflow-y: scroll;

  li {
    line-height: 47px;
  }
}

#mmenu .mm-list a.mm-subclose {
  text-indent: 0;
  padding: 20px 0 20px 60px;
  line-height: 30px;
  display: inline-block;
}

.mm-list > li.my-account {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 0;

  a {
    line-height: 30px;
    box-sizing: border-box;

    &.mm-subopen {
      top: 11px;
    }
  }

  .small-title {
    font-size: 12px;
    color: #a0a0a0;
    text-transform: uppercase;
  }

  .user-initials {
    background: $nav-background-color;
    color: $nav-links-color;
    padding: 5px;
    min-width: 32px;
    height: 32px;
    line-height: 23px;
    border-radius: 50%;
    text-align: center;
    display: inline-block;
  }

  .register-now {
    font-size: 13px;
    padding-left: 40px;
  }
}

#mmenu .support-menu .support-menu-cname {
  text-overflow: initial;
}

.mm-list:not(.mm-current) > li.my-account {
  z-index: -1;
  display: none;
}

.mm-list:not(.mm-current) > li.support-menu {
  z-index: -1;
  display: none;
}

#mmenu {
  font-family: NeoSansProMedium, sans-serif;
}

#mmenu ul[data-depth="0"].mm-current {
  padding-top: 70px;

  + div.language-selector {
    display: block;
  }
}

#mmenu div.language-selector {
  display: none;
  position: absolute;
  z-index: 0;
  left: 35px;
  top: 25px;
  width: 100px;
  height: auto;

  .fa {
    color: $nav-background-color;
  }

  .dropdown-menu.show {
    border: none;
    padding: 10px 0;

    .dropdown-item {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
  }
}

#mmenu .lang-flag {
  width: 24px;
  margin-right: 5px;
}

#mmenu a.close-menu {
  content: '';
  background: url('../img/icons/close-blue-icon.svg') no-repeat;
  background-size: 12px;
  display: inline-block;
  z-index: 1;
  right: 25px;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 30px;
}

#mmenu a.btn.btn-primary {
  display: inline-block;
  padding: 0 20px;
  height: 34px;
  line-height: 34px !important;
  color: #fff;
  margin: 10px 0 10px 40px;
}

#mmenu li.sign-out a.btn.btn-primary {
  margin: 10px 0;
}

#mmenu li.sign-out {
  text-align: center;
}

#mmenu li.my-account a.btn.btn-primary {
  height: 50px;
  line-height: 50px !important;
  width: calc(100% - 80px);
  font-size: 14px;
}

.main-menu #mmenu.hidden-desktop ul {
  display: none;
}

li:not(.globomenu-item-level-0) a.globomenu-target.globomenu-has-content > span:after {
  background: url('../img/icons/arrow-down.svg') no-repeat left center;
  right: 2px;
  transform: rotate(-90deg);
  margin-top: 0;
}

.mm-list {
  .contact-details {
    &.mm-selected {
      > a,
      > span {
        background: none !important;
      }
    }

    position: relative !important;

    .store-info-title {
      display: inline-block !important;
    }

    a {
      position: relative;
      color: $nav-background-color;
      min-width: 24px;
      width: auto;
      height: 24px;
      line-height: 22px;
      border: 1px solid $nav-background-color;
      border-radius: 12px;
      display: inline-block;
      margin: 0;
      float: left;

      &:first-child {
        margin-right: 10px;
      }

      &.phone {
        background: #fff url('../img/icons/phone-icon-small.svg') no-repeat 6px center;
      }

      &.email {
        background: #fff url('../img/icons/email-icon-small.svg') no-repeat 6px center;
        right: auto;
      }

      &.teamviewer {
        background: #fff url('../img/icons/team-viewer.svg') no-repeat 1px center;
        background-size: 20px 15px;
      }

      span.value {
        display: none;
        -webkit-transition: width 1s ease-in-out;
        -moz-transition: width 1s ease-in-out;
        -o-transition: width 1s ease-in-out;
        transition: width 1s ease-in-out;
        line-height: 21px;
        font-family: GalaxiePolarisBook, sans-serif;
        font-size: 12px;
        font-weight: normal;
      }

      span.blank {
        width: 22px;
        height: 24px;
        position: absolute;
        left: 0;
        top: 0;
        display: block;
      }

      &.show {
        width: auto;
        padding: 0 5px 0 22px;

        span.value {
          width: auto;
          display: inline-block;
        }
      }
    }
  }

  .chat {
    text-transform: uppercase;

    .livechat_button {
      background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yNCAxMkMyNCAxNy43OTkgMTguNjI3IDIyLjUgMTIgMjIuNUMxMC44MTE1IDIyLjUwMTYgOS42Mjc4OCAyMi4zNDczIDguNDc5NSAyMi4wNDFDNy42MDM1IDIyLjQ4NSA1LjU5MiAyMy4zMzcgMi4yMDggMjMuODkyQzEuOTA4IDIzLjk0IDEuNjggMjMuNjI4IDEuNzk4NSAyMy4zNDlDMi4zMjk1IDIyLjA5NSAyLjgwOTUgMjAuNDI0IDIuOTUzNSAxOC45QzEuMTE2IDE3LjA1NSAwIDE0LjY0IDAgMTJDMCA2LjIwMSA1LjM3MyAxLjUgMTIgMS41QzE4LjYyNyAxLjUgMjQgNi4yMDEgMjQgMTJaTTcuNSAxMkM3LjUgMTIuMzk3OCA3LjM0MTk2IDEyLjc3OTQgNy4wNjA2NiAxMy4wNjA3QzYuNzc5MzYgMTMuMzQyIDYuMzk3ODIgMTMuNSA2IDEzLjVDNS42MDIxOCAxMy41IDUuMjIwNjQgMTMuMzQyIDQuOTM5MzQgMTMuMDYwN0M0LjY1ODA0IDEyLjc3OTQgNC41IDEyLjM5NzggNC41IDEyQzQuNSAxMS42MDIyIDQuNjU4MDQgMTEuMjIwNiA0LjkzOTM0IDEwLjkzOTNDNS4yMjA2NCAxMC42NTggNS42MDIxOCAxMC41IDYgMTAuNUM2LjM5NzgyIDEwLjUgNi43NzkzNiAxMC42NTggNy4wNjA2NiAxMC45MzkzQzcuMzQxOTYgMTEuMjIwNiA3LjUgMTEuNjAyMiA3LjUgMTJaTTEzLjUgMTJDMTMuNSAxMi4zOTc4IDEzLjM0MiAxMi43Nzk0IDEzLjA2MDcgMTMuMDYwN0MxMi43Nzk0IDEzLjM0MiAxMi4zOTc4IDEzLjUgMTIgMTMuNUMxMS42MDIyIDEzLjUgMTEuMjIwNiAxMy4zNDIgMTAuOTM5MyAxMy4wNjA3QzEwLjY1OCAxMi43Nzk0IDEwLjUgMTIuMzk3OCAxMC41IDEyQzEwLjUgMTEuNjAyMiAxMC42NTggMTEuMjIwNiAxMC45MzkzIDEwLjkzOTNDMTEuMjIwNiAxMC42NTggMTEuNjAyMiAxMC41IDEyIDEwLjVDMTIuMzk3OCAxMC41IDEyLjc3OTQgMTAuNjU4IDEzLjA2MDcgMTAuOTM5M0MxMy4zNDIgMTEuMjIwNiAxMy41IDExLjYwMjIgMTMuNSAxMlpNMTggMTMuNUMxOC4zOTc4IDEzLjUgMTguNzc5NCAxMy4zNDIgMTkuMDYwNyAxMy4wNjA3QzE5LjM0MiAxMi43Nzk0IDE5LjUgMTIuMzk3OCAxOS41IDEyQzE5LjUgMTEuNjAyMiAxOS4zNDIgMTEuMjIwNiAxOS4wNjA3IDEwLjkzOTNDMTguNzc5NCAxMC42NTggMTguMzk3OCAxMC41IDE4IDEwLjVDMTcuNjAyMiAxMC41IDE3LjIyMDYgMTAuNjU4IDE2LjkzOTMgMTAuOTM5M0MxNi42NTggMTEuMjIwNiAxNi41IDExLjYwMjIgMTYuNSAxMkMxNi41IDEyLjM5NzggMTYuNjU4IDEyLjc3OTQgMTYuOTM5MyAxMy4wNjA3QzE3LjIyMDYgMTMuMzQyIDE3LjYwMjIgMTMuNSAxOCAxMy41WiIgZmlsbD0iIzJBNTI3RSIvPgo8L3N2Zz4K) no-repeat right 30px center;
    }

    a.chat-bubble {
      font-size: 15px;
      color: #003B5C;
    }
  }
}

.support-menu {
  position: absolute !important;
  top: 8px !important;
  border: 0 !important;

  > span {
    text-transform: none !important;
  }

  a.mm-subopen {
    width: 100% !important;

    &:after {
      display: none !important;
    }
  }

  .support i {
    background: url('../img/icons/support-icon-blue.svg') no-repeat;
    display: inline-block;
    height: 32px;
    padding-left: 35px;
    font-style: normal;
    line-height: 32px;
  }
}

#mmenu {
  .support-menu {
    right: 30px;
    margin-top: -2px;
  }
}

@media(max-width: 1080px) {
  #mmenu {
    .mm-list .contact-details-container {
      border-top: 1px solid #dadada;
      border-bottom: 0;
      min-height: 61px;

      .store-info-title {
        line-height: 46px;
        height: 54px;
        text-transform: uppercase;
        font-size: 15px;
        width: 100%;
        padding: 10px 20px 10px 40px;
        font-family: NeoSansProMedium, sans-serif;
        color: $crd-primary-color;
        cursor: pointer;
        margin: 0;
      }

      .contact-details {
        height: 0;
        padding: 0;
        width: 100%;

        &.show {
          height: auto;
          padding: 15px 20px 10px 40px;
          border-top: 1px solid #dadada;
        }
      }
    }

    .support-text {
      padding-left: 15px;
      font-family: GalaxiePolarisBook, sans-serif;
      color: #666;
      font-weight: normal;
      display: block;
    }

    .store-info-title {
      .dropdown-arrow {
        margin-top: 10px;
      }

      .dropdown-arrow.remove {
        margin-top: 7px;
      }
    }
  }
}
