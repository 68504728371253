//	Arrows
@mixin arrow
{
	content: '';
	background: url('../img/icons/arrow-left.svg') no-repeat;
	display: block;
	width: 8px;
	height: 14px;
	margin-bottom: -5px;
	position: absolute;
	bottom: 50%;
}
@mixin arrow-prev
{
	border-right: none;
	border-bottom: none;
	@include vendor-prefix( "transform", rotate( 180deg ) );
}
@mixin arrow-next
{
	border-top: none;
	border-left: none;
}
