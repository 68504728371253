//	The size of the buttons
$btnSize: 60px !default;
$padding: 10px !default;
$fontSize: 15px !default;

//	Animations
$transitionDuration: 0.4s !default;
$transitionFunction: ease !default;

$subOffset: 40% !default;


@import "sizing";
@import "colors";
@import "arrows";


//	Borders
@mixin border( $border, $pseudo, $size, $pos1, $pos2 )
{
	&:#{$pseudo}
	{
		content: '';
		border-#{$border}-width: 1px;
		border-#{$border}-style: solid;
		display: block;
		#{$size}: 100%;
		position: absolute;
		#{$pos1}: 0;
		#{$pos2}: 0;
	}
}
@mixin border-top
{
	@include border( "top", ":before", "width", "top", "left" );
}
@mixin border-right
{
	@include border( "right", ":after", "height", "right", "top" );
}
@mixin border-bottom
{
	@include border( "bottom", ":after", "width", "bottom", "left" );
}
@mixin border-left
{
	@include border( "left", ":before", "height", "left", "top" );
}

//	Misc mixins
@mixin vendor-prefix( $prop, $val )
{
	-webkit-#{$prop}: $val;
	-moz-#{$prop}: $val;
	-ms-#{$prop}: $val;
	-o-#{$prop}: $val;
	#{$prop}: $val;
}
@mixin ellipsis
{
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}
