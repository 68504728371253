.supercheckout-new {
  .sc-title {
    font-size: 18px;
    color: $dark-gray;
    line-height: 27px;
    padding: 0 30px 15px;
  }

  .sc-label {
    color: $border-gray;
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    text-transform: none;
  }

  .supercheckout-block {
    background: #fff;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 30px;
    margin-bottom: 30px;

    &.confirmCheckoutBack {
      padding: 30px 0 0 0;
    }

    &.no-padding-h {
      padding: 30px 0;

      .address-rows {
        padding: 0 30px;
        border: 0;
        min-height: auto;
        margin: 0;
      }

      #supercheckout-shipping {
        padding: 0 30px;
      }
    }
  }

  .supercheckout-separator {
    height: 1px;
    background: $border-gray;
    margin: 30px 0;
  }

  .supercheckout_address_detail {
    font-size: 14px;
    line-height: 21px;
    color: $dark-gray;
    font-weight: normal;
  }

  #supercheckout-payment {
    min-height: 216px;
  }

  #payment-method {
    min-height: 88px;
  }

  .edit-delivery-addr {
    float: right;
  }

}

#velsof_supercheckout_form .supercheckout-new .row {
  display: flex;
}

.disabled-button {
  opacity: 0.5;
}

#supercheckout-fieldset {
  .supercheckout-new {
    .col-right {
      display: flex;
      flex-direction: column;
    }

    .nice-select {
      height: 50px;
      border: 2px solid #D1D1D1;
      box-sizing: border-box;
      border-radius: 4px;
      width: 100%;
      max-width: 200px;
    }

    .carrier, .payment-box {
      margin: 0;
      padding: 0;
    }

    .po-titles {
      justify-content: flex-start;
      margin: 20px 0;

      @media (min-width: 767px) and (max-width: 991px) {
        flex-direction: column;
      }

      .po-title {
        display: flex;
        justify-content: flex-start;
        margin-right: 20px;

        &:last-child {
          margin-right: 0;
        }

        label {
          font-size: 14px;
          line-height: 21px;
          color: $crd-primary-color;
        }
        .credit-card{
          line-height: 12px;
          color: #fff;
          font-size: 13px;
          font-weight: 500;
          letter-spacing: 0;
          text-align: left;
          padding: 2px 10px 0 10px;
          margin: 2px;
          height: 18px;
          border-radius: 12px;
          opacity: 1;
          background-color: #4da767;
        }
      }
    }

    .payment-additional-info .additional-information {
      padding: 10px 20px;
      background: $gray-lighter;
      border-radius: 8px;

      p {
        font-size: 13px;
        color: $dark-gray;
        line-height: 19px;
        font-weight: normal;
        margin: 0;
      }
    }

    #supercheckout-comments {
      margin: 0;
      padding: 0;
    }

    #supercheckout-comment_order {
      margin: 15px 0;
      border: 2px solid $border-gray;
      box-sizing: border-box;
    }

    .mw320 .nice-select {
      max-width: 320px;
    }

    .span-text {
      font-weight: normal;
      font-size: 12px;
    }

    #supercheckout-agree {
      .conditions {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        margin-bottom: 5px;

        .toggle-switch {
          margin: 0 10px 0 0;
        }

        .agree-terms {
          font-size: 14px;
          line-height: 21px;
          position: relative;
          max-width: calc(100% - 50px);

          .denote-procurement {
            //position: absolute;
            top: 2px;
          }
        }
      }
    }

    #placeorderButton {
      margin: 30px 0;
      padding: 0 !important;
      text-align: left;
    }

    .one-order-button .btn {
      font-size: 16px;
      font-weight: bold;
      line-height: 62px !important;
      height: 60px;
      padding: 0 40px;
      text-transform: none;
      border-radius: 35px;
      width: auto;
      min-width: 200px;
    }

    #confirmCheckout {
      article .article-qty .input-box input.qty-input {
        max-width: 100%;
      }

      .right-side-cart {
        position: relative;

        .articles {
          padding-left: 30px;
          padding-right: 30px;
          overflow: hidden;
          overflow-y: scroll;
          max-height: 430px;
        }

        article {
          .article-image {
            padding: 10px;
            box-shadow: 0 2px 5px $border-gray;
            border-radius: 8px;
            background: #fff;
          }
        }
      }

      .sc-cart-summary {
        width: 100%;
        box-shadow: 0px -4px 26px rgba(0, 0, 0, 0.05);
        padding: 8px 30px 10px 30px;
        background: #fff;
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;

        .row {
          margin: 0;
          align-items: center;
          padding: 5px 0;
          position: relative;
        }

        .totals {
          .labels, .total-label {
            color: $dark-gray;
            font-weight: normal;
            font-size: 13px;
            line-height: 28px;
            @media (max-width: 1440px) {
              line-height: 20px;
            }
          }

          .values, .total-price {
            color: $brand-primary;
            font-weight: bold;
            font-size: 13px;
            line-height: 33px;
            float: right;
            @media (max-width: 1440px) {
              line-height: 20px;
            }
          }

          .total-label, .total-price {
            font-size: 16px;
            font-weight: bold;
            line-height: 38px;
            @media (max-width: 1440px) {
              line-height: 20px;
            }
          }
          .total_discount .labels {
            color: $accent-green;
          }
        }
      }
    }

    #shippingAddressesModal {
      background-color: inherit !important;
      padding: inherit !important;
      border: inherit !important;
    }
  }
}

.border-supercheckout{
  height: 1px;
  background: #d1d1d1;
}
.crd-supercheckout-discounts {
  @media (max-width: 1000px) {
    margin-top:unset !important;
  }
  .cart_discount {
    .crd-ellipsis {
      @include ellipsis(152px);
    }

    @media (min-width: 1000px) {
      &.m-0 {
        margin-left: 0 !important;
      }
      margin-left: 90px !important;
    }
    @media (min-width: 1440px){
      padding: 0 !important;
    }
    .title{
      .labels{
        margin-left: -10px;
        .row{
          .fix-mobile {
            @media (max-width: 1000px) {
              display: none;
            }
          }
          .col-xs-4{
            a{
              color: #4DA767;
              line-height: 14px;
              width: 100%;
              height: 100%;
              text-align: center;
            }
          }
        }
      }
    }
  }
  .card_discount{
    .col-xs-2{
      margin-top: -4px;
    }
  }
}


//supercheckout discount area
.crd-sc {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &.discount-wrapper {
    padding: 0 15px;
  }

  &.discount-label {
    background-color: #4DA767;
    border-radius: 12px;
    padding: 2px 2px 2px 10px;
    margin-left: 20px;
    margin-right: auto;

    span {
      color: #fff;

      &.crd-ellipsis {
        @include ellipsis(150px);
      }

      + span {
        margin-left: 5px;
        font-weight: 600;
      }
    }

    a {
      border-radius: 50%;
      width: 20px;
      height: 20px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 20px;
      color: #4DA767;
    }
  }

  .crd-sc-label {
    color: #666;
    font-size: 13px;
    line-height: 28px;
  }

  .crd-sc-value {
    color: #003b5c;
    font-size: 13px;
    font-weight: 600;
    display: inline-block;
  }

  @media (max-width: 1024px) {
    flex-wrap: wrap;

    &-label {
      flex: 1 1 100%;
    }

    &.discount-label {
      margin-left: 0;
    }

    .crd-ellipsis {
      max-width: 70px;
    }
  }
}

.subtotal.separator {
  .labels {
    font-weight: bold !important;
  }
}

.title {
  @extend .col-xl-8;
  @extend .col-6;

  .labels {
    .row {
      padding: 0 2px 0 10px !important;
      height: 24px;
      border-radius: 12px;
      opacity: 1;
      background-color: rgba(77, 167, 103, 1);

      .col-xs-2 {
        opacity: 1;
        color: rgba(255, 255, 255, 1);
        font-weight: 500;
        letter-spacing: 0;
        text-align: left;
        font-size: 15px;
        line-height: 15px;
        padding-bottom: 3px;
        b{
          padding-right: 8px;
        }
      }

      .col-xs-4 {
        border-radius: 50%;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(255, 255, 255, 1);

        a {
          display: inline-block;
          text-decoration: none;
          color: inherit;
        }
      }
    }
  }
}

#shipping_address_id {
  display: none;
}

#shippingAddressesModal {
  h3 {
    font-size: 18px;
    font-weight: 400;
  }

  .modal-header, .modal-footer {
    border: none;
  }

  .modal-dialog {
    max-width: 360px;
  }

  .flex-container {
    display: flex;
  }

  input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;
  }

  label {
    width: 320px;
    display: inline-block;
    background-color: white;
    padding: 10px 20px;
    font-family: sans-serif, Arial;
    font-size: 16px;
    border: 2px solid #D1D1D1;
    border-radius: 2px;
    cursor: pointer;
    text-align: left;
    margin-bottom: 20px;
    line-height: 20px;
    position: relative;

    .fa {
      display: none;
    }

    &:hover {
      border: 2px solid $brand-primary;
    }

    &.checked {
      border: 2px solid $brand-primary;

      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 0;
        height: 0;
        border-top: 25px solid $brand-primary;
        border-left: 25px solid transparent;
      }

      .fa {
        display: inline-block;
        color: white;
        position: absolute;
        right: 2px;
        top: 2px;
        font-size: 10px;
        z-index: 2;
      }
    }
  }
}
@media (max-width: 1440px) {
  #supercheckout_voucher_input_row {
    padding-bottom: 10px;
  }
}
@media (min-width: 1440px) {
  #supercheckout_voucher_input_row {
    padding: 10px 0;
  }
  #supercheckout_voucher_input_row #voucher-form {
    .row.no-gutters {
      margin-right: -15px !important;
    }
    #button-coupon {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    #discount_name.form-control:focus {
      outline: none;
      background: #ebebeb;
    }
  }
}

@media (max-width: 768px) {
  #supercheckout-fieldset .supercheckout-new #confirmCheckout .right-side-cart .articles {
    display: none;
  }
  #supercheckout-fieldset .supercheckout-new #confirmCheckout .sc-cart-summary {
    position: static;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  #supercheckout-fieldset .supercheckout-new .confirmCheckoutBack {
    min-height: 100px;
  }
  .supercheckout-new .supercheckout-block.confirmCheckoutBack {
    padding: 0;
  }
}

@media (max-width: 450px) {
  #supercheckout-fieldset {
    .supercheckout-new {
      #supercheckout-agree {
        .conditions {
          align-items: baseline;

          .agree-terms {
            .denote-procurement {
              position: static;
            }
          }
        }
      }

      .col-right {
        display: none;
      }

      .one-order-button .btn {
        width: 100%;
      }
    }
  }
  .back-wrapper {
    display: block;
  }
}
