#contact {
  #left-column,
  #content-wrapper.col-md-9,
  #content-wrapper.col-sm-8 {
    flex: 0 0 50%;
    max-width: 50%;
    @media (max-width: 450px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  .contact-rich,
  .contact-form {
    h4 {
      font-size: 20px;
      line-height: 24px;
      color: $crd-primary-color;
      text-transform: none;
      padding: 0;

      &:after {
        display: none;
      }
    }
  }

  .contact-rich {
    h4 {
      margin: 50px 0;
      @media (max-width: 768px) {
        margin: 20px 0;
      }
    }

    .block {
      background: #fff;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      padding: 50px 40px;
      margin-bottom: 30px;
      @media (max-width: 768px) {
        padding: 20px 30px;
      }

      .data {
        color: #000;
        line-height: 27px;
        font-size: 15px;
      }

      .icon {
        width: 26px;
        margin-right: 30px;

        .fa {
          color: #979797;
        }
      }
    }
  }

  .contact-form {
    padding: 50px 70px;
    background: #fff;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    @media (max-width: 810px) {
      padding: 20px 30px;
    }

    h4 {
      margin: 0 0 20px 0;
    }

    .form-footer {
      .btn.btn-primary {
        font-weight: 700;
        line-height: 37px !important;
      }
    }
  }

  @media (max-width: 768px) {
    #content-wrapper #main {
      padding: 0 20px;
    }
  }
}
