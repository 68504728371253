//removed from Bourbon 5.0.0

// Convert pixels to ems
// eg. for a relational value of 12px write em(12) when the parent is 16px
// if the parent is another value say 24px write em(12, 24)
@use "sass:math";

$em-base: 16px !default;

@function strip-units($value) {
  @return math.div($value, ($value * 0 + 1));
}

@function em($pxval, $base: $em-base) {
  @if not unitless($pxval) {
    $pxval: strip-units($pxval);
  }
  @if not unitless($base) {
    $base: strip-units($base);
  }

  @return math.div($pxval, $base) * 1em;
}

@function rem($pxval) {
  @if not unitless($pxval) {
    $pxval: strip-units($pxval);
  }

  $base: $em-base;
  @if not unitless($base) {
    $base: strip-units($base);
  }

  @return calc($pxval/$base) * 1rem;
}

$h3-font-size: rem(15px);
.btn {
  white-space: nowrap;
}

.input-group {
  width: 100%;
  display: table;
  border-collapse: separate;
}

.container:after {
  content: "";
  display: table;
  clear: both;
}

.input-group-addon {
  padding: .5rem .75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  color: #8b8b8b;
  text-align: center;
  background-color: #f6f6f6;
  border: 1px solid rgba(0, 0, 0, .15);
  border-radius: 0;
}
