*,
*:before,
*:after {
  box-sizing: border-box;
}

.thanks-note p {
  height: 100%;
  margin: 0;
}

.thanks-note {
  margin: 60px auto;
  position: relative;
  font-family: NeoSansProMedium, sans-serif;
  height: 650px;
}

.thanks-note h1 {
  top: 140px;
  margin: 0;
  font-size: 120px;
  font-weight: 500;
  width: 50%;
  position: absolute;
  text-transform: uppercase;
  text-align: right;
}

.thanks-note--content {
  height: 650px;
  position: relative;
  background: url("../img/icons/thanks-wave-desktop.svg") no-repeat;
  background-size: 100% 100%;
  color: white;
}

.thanks-note--content--wrapper {
  margin: 0 auto;
  max-width: 1400px;
  padding: 0 40px;
  display: flex;
  align-items: flex-end;
  padding-top: 140px;
  position: relative;
}

.thanks-note > h1 {
  background-color: rgb(0, 97, 118);
  color: transparent;
  text-shadow: 0px 0px 6px rgba(89, 221, 251, 0.386);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
}

.thanks-note--content h1 {
  color: white;
  text-shadow: rgba(0, 0, 0, 0.636) 2px 5px 16px;
}

.thanks-note .plane-desktop {
  position: absolute;
  right: -20px;
  bottom: -20px;
  filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.4));
}

.thanks-note .plane-mobile {
  position: absolute;
  display: none;
  filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.4));
}

.thanks-note--content p {
  white-space: pre-line;
  line-height: 1.6;
  font-size: 15px;
  margin-top: 30px;
  color: rgba(255, 255, 255, 1);
  position: relative;
  font-family: GalaxiePolarisBook, sans-serif;
}

.thanks-note--content .first b {
  font-size: 60px;
  line-height: 60px;
  font-weight: 500;
  text-align: right;
  display: block;
  padding-bottom: 40px;
}

.thanks-note--content .second b {
  font-size: 26px;
  font-weight: 600;
  display: block;
  font-family: NeoSansProLight, sans-serif;
  line-height: 32px;
}

.thanks-note--content .first,
.thanks-note--content .second {
  flex: 1;
}

.thanks-note--content .first {
  padding-right: 0;
}

.thanks-note--content .second > * {
  padding: 0 20px 0 120px;
  max-width: 540px;
}


@media only screen and (max-width: 1024px) {

  .thanks-note h1 {
    font-size: 90px;
    width: 50%;
    top: 130px;
  }

  .thanks-note,
  .thanks-note--content {
    height: 550px;
  }

  .thanks-note--content--wrapper {
    padding-top: 100px;
  }

  .thanks-note--content .first b {
    font-size: 40px;
    line-height: 40px;
  }

  .thanks-note--content .second b {
    font-size: 20px;
  }

  .thanks-note--content .second > * {
    padding: 0 0 0 80px;
    max-width: 400px;
  }

  .thanks-note .plane-desktop {
    width: 70px;
  }

  .thanks-note--content p {
    line-height: 1.4;
  }
}

@media only screen and (max-width: 820px) {

  .thanks-note h1 {
    font-size: 80px;
    width: 50%;
    top: 125px;
  }

  .thanks-note,
  .thanks-note--content {
    height: 500px;
  }

  .thanks-note--content--wrapper {
    padding-top: 110px;
  }

  .thanks-note--content .first b {
    font-size: 36px;
    line-height: 36px;
  }

  .thanks-note--content .second > * {
    padding: 0 0 0 40px;
  }
}

@media only screen and (max-width: 540px) {

  .thanks-note,
  .thanks-note--content {
    height: unset;
    max-height: 650px;
    min-height: 500px;
  }

  .thanks-note {
    margin: 30px auto;
  }

  .thanks-note--content {
    width: 100%;
    position: relative;
    background: url("../img/icons/thanks-wave-mobile.svg") no-repeat;
    background-size: 100% 100%;
  }

  .thanks-note--content--wrapper {
    padding-top: 120px;
    flex-direction: column;
    align-items: flex-start;
    height: fit-content;
  }

  .thanks-note--content .first,
  .thanks-note--content .second {
    padding: 0;
  }

  .thanks-note--content .second > * {
    padding: 0;
  }

  .thanks-note h1 {
    font-size: 60px;
    text-align: left;
    width: unset;
    left: 40px;
    top: 26px;
  }

  .thanks-note .plane-desktop {
    display: none;
  }

  .thanks-note .plane-mobile {
    right: 30px;
    top: 70px;
    bottom: unset;
    display: block;
    width: 70px;
    z-index: 3;
  }

  .thanks-note--content p {
    font-size: 14px;
  }

  .thanks-note--content .first b {
    font-size: 18px;
    line-height: 18px;
    font-weight: 400;
    padding-bottom: 40px;
  }

  .thanks-note--content .second b {
    font-size: 20px;
    font-weight: 400;
    line-height: 29px;
  }
}
