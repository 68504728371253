#product {
  h1 {
    font-size: 26px;
    line-height: 32px;
    margin-bottom: 20px;
    color: $dark-gray;
    word-break: break-word;
  }

  .product-title-top {
    display: none;
  }

  .product-prices {
    margin: 0;

    .product-price {
      margin-bottom: 0;
    }

    .price-tax {
      margin-top: 5px;
      font-size: 12px;
      font-weight: bold;
      color: $dark-gray;
    }

    .current-price {
      display: flex;
      align-items: center;
    }
  }

  #add-to-cart-or-refresh {
    div.ui-btn.ui-input-btn.ui-corner-all.ui-shadow {
      display: none;
    }
  }

  .customer-addon {
    &.price-right {
      display: flex;
      align-items: center;
      margin-top: 4px;

      .info-assortment {
        background: url('../img/icons/info-assort-icon.svg') no-repeat;
        display: inline-block;
        width: 26px;
        height: 26px;
        background-size: 26px 26px;
        margin-right: 5px;
      }

      .add-to-fav {
        background: url('../img/icons/favourite-icon.svg') no-repeat;
        background-size: contain;
        display: inline-block;
        width: 30px;
        height: 26px;
        margin: 0;

        &.active, &:hover {
          background: url('../img/icons/favourite-icon-active.svg') no-repeat;
          background-size: cover;
        }
      }
    }
  }

  [id^="product-description-short-"] {
    line-height: 33px;
    font-size: 16px;
    color: $dark-gray;
    margin-bottom: 15px;
  }

  .toggleDescription {
    font-weight: bold;
    color: $crd-secondary-color;
    margin-top: 10px;
    display: inline-block;
  }

  #main .images-container .js-qv-mask {
    border: none;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }

  .product-information .product-quantity {
    .qty {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 16px;
      margin-bottom: 20px;

      label {
        font-weight: bold;
        line-height: 24px;
        margin: 0 25px 0 0;
      }

      .input-box {
        width: 140px;
      }

      input.qty-input {
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
        border: none;
        border-radius: 25px;
        height: 50px;
        line-height: 50px;
        color: $crd-primary-color;
      }

      .value-button {
        position: absolute;
        width: 16px;
        height: 16px;
        cursor: pointer;
        top: 18px;

        &.decrease {
          left: 18px;
          background: url('../img/icons/pp-minus.svg') no-repeat center;
        }

        &.increase {
          right: 18px;
          background: url('../img/icons/pp-plus.svg') no-repeat center;
        }
      }
    }
  }

  .product-add-to-cart {
    margin: 20px 0 0 0;

    .add {
      float: none;
      width: 100%;
      max-width: 520px;
      margin-bottom: 0;

      .no-stock {
        color: #8ca7b5;
        border: 1px solid #e0e0e0;
        background: #fff;
        cursor: not-allowed;

        .warning {
          background: url('../img/icons/warning-icon.svg') no-repeat left 0px;
          padding-left: 16px;
        }
      }

      .btn {
        width: 100%;
      }

      .btn-big:not(.new-btn-primary):not(.new-btn-secondary) {
        height: 50px;
        border-radius: 40px;
        font-size: 16px;
        line-height: 50px !important;
        text-transform: uppercase;
        text-align: center;
        font-family: GalaxiePolarisBook, sans-serif;
        font-weight: bold;
      }
    }
  }

  .pi-additional {
    font-size: 12px;
    line-height: 18px;
    font-weight: bold;
    color: $dark-gray;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    span {
      font-weight: normal;
      color: #000;
    }
  }

  .product-view-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .product-minimal-quantity {
    font-size: 11px;
    font-style: italic;
    margin-top: 5px;
    margin-bottom: 0;
  }

  .tabs {
    padding: 30px;
    margin-top: 20px;
    border-radius: 4px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    background: #fff;
    position: relative;

    .pi-additional {
      display: inherit;
      text-transform: uppercase;
      position: absolute;
      right: 30px;

      span {
        font-weight: normal;
        color: $crd-primary-color;
      }
    }

    .nav-tabs {
      margin-bottom: 0;

      li {
        margin-right: 40px;
      }

      .nav-link {
        color: $crd-primary-color;
        font-size: 12px;
        line-height: 18px;
        font-weight: bold;
        text-transform: uppercase;
        background: none;
        border: none;
        padding: 0 0 4px 0;
        margin: 0;
        min-width: auto;
        letter-spacing: 0;

        &.active {
          background: none;
          color: $dark-gray;
          border-bottom: 3px solid #63B5DC;
        }
      }
    }

    .tab-content {
      padding: 20px 0 0 0;
      color: $dark-gray;
      font-size: 14px;
      line-height: 28px;
      background: none;
      border: none;

      .tab-pane {
        padding: 0;
        margin: 0;

        label {
          color: $dark-gray;
          text-transform: capitalize;
          margin-bottom: 0;
          font-size: 12px;
          line-height: 18px;
        }

        span {
          color: #000;
          font-size: 12px;
          line-height: 25px;
          font-weight: normal;
        }
      }
    }
  }

  .products-in-category .title {
    font-family: GalaxiePolarisBook, sans-serif;
    font-size: 13px;
    margin: 30px 0 20px 0;
    color: $dark-gray;
    font-weight: bold;

    &:after {
      display: none;
    }
  }

  .replacement-available {
    margin-top: 20px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    padding: 30px 20px;

    .title {
      color: #787878;
      margin-bottom: 24px;
      text-transform: uppercase;
      margin-top: 0;
      font-size: 13px;
    }

    .prod-img {
      width: 70px;
      margin-right: 15px;
      float: left;

      img {
        max-width: 100%;
        height: 75px;
        width: 70px;
        display: block;
        border: 1px solid #f7f7f7;
        padding: 10px;
      }
    }

    .prod-details {
      float: left;
      width: calc(100% - 85px);
    }

    .product-title {
      color: $gray-darker;
      max-height: 38px;
      line-height: 18px;
      overflow: hidden;
      margin: 11px 0 4px;
      font-weight: normal;
    }

    .product-price {
      .price {
        font-weight: bold;
      }
    }

    .product {
      margin-bottom: 30px;
    }
  }

  .block-prices {
    display: flex;

    .customer-addon {
      @media (max-width: 450px) {
        margin-top: 3px;
      }
    }
  }

  .tier-prices.product-pricing {
    margin-top: 20px;
  }

  img.thumb {
    height: auto;
  }
}

@media (min-width: 769px) {
  #product {
    .product-view-left {
      flex: 1;
    }

    .product-view-right {
      flex: 1;
    }
  }
}

@media (max-width: 1080px) {
  #product {
    .tabs {
      margin-top: 25px;

      .nav-tabs .nav-item {
        margin-top: 0;
      }
    }

    h1 {
      font-size: 22px;
    }

    .product-add-to-cart {
      margin-top: 0;
    }
  }
}

@media (max-width: 1024px) {
  #product {
    .product-information .product-quantity {
      .qty, .add {
        margin-bottom: 20px;
      }
    }

    [id^="product-description-short-"] {
      font-size: 16px;
      line-height: 25px;
    }

    #content {
      width: 100%;
      max-width: 100%;
      margin-bottom: 40px;
      @media (max-width: 450px) {
        margin-bottom: 10px;
      }
    }

    .bottom-content, .tabs {
      margin-top: 25px;
    }
  }
  .products-in-category .products-flex.products {
    .product-block:nth-child(5) {
      display: none;
    }
  }
}

@media (max-width: 768px) {
  //.products-in-category .products-flex.products {
  //  .product-block:nth-child(4) {
  //    display: none;
  //  }
  //}

  //.products-in-category .products-flex.products {
  //  .product-block:nth-child(4) {
  //    display: block;
  //  }
  //
  //  flex-wrap: wrap;
  //  flex-direction: row;
  //}
  #product .tabs .nav-tabs .nav-link img,
  #product .tabs .nav-tabs .nav-link.active img {
    display: inline-block;
  }

  #product-modal .modal-dialog {
    max-width: 96%
  }
  #product .login-link {
    margin: 0;
  }
}

@media (max-width: 1379px) and (min-width: 1024px) {
  .products-in-category {
    .products-flex.products .product-block {
      &:nth-child(4) {
        display: none;
      }
    }
  }
}

@media (max-width: 768px) {
  .modal {
    padding-top: 0;

    .modal-dialog {
      max-width: 100%;
      width: auto;

      .products .cols {
        margin-bottom: 15px;
      }
    }
  }

  #product {
    .tier-prices.product-pricing {
      margin-top: 1rem;
    }

    .product-title-top {
      display: block;
      font-size: 24px;
      line-height: 36px;
    }

    h1.product-title {
      display: none;
    }

    .replacement-available .product-title {
      display: block;
    }

    .product-information {
      .product-quantity {
        .qty {
          justify-content: space-between;
          margin-top: 0px;
        }
      }
    }

    .tabs {
      margin-top: 0;
      background: transparent;
      box-shadow: none;

      .pi-additional {
        position: static;
      }
    }

    .toggleDescription {
      display: none;
    }
  }
}

@media (max-width: 767px) {
  #_mobile_min_qty_info {
    margin-top: -15px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  #_desktop_min_qty_info {
    margin-top: -20px;
  }
  #product .tabs .pi-additional {
    margin-top: 20px;
  }
  #product .bottom-content {
    //margin-top: 0;
  }
}

@media (min-width: 769px) and (max-width: 1080px) {
  #product .tabs .pi-additional {
    display: none;
  }
}

@media (min-width: 500px) and (max-width: 1025px) {
  #product {
    .block-prices {
      position: relative;
    }

    .product-prices {
      .price-tax {
        position: absolute;
        right: 0;
        top: 2px;
        background: white;
        padding: 9px;
        border-radius: 4px;
        margin-top: 0;
      }
    }
  }
}

@media (max-width: 768px) {
  #product {
    .panel-group {
      padding: 0 20px;
      border-radius: 4px;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
      background: #fff;
      width: 100%;
      margin-top: 0;

      .panel-heading {
        padding-left: 0;

        .panel-title {
          padding-bottom: 0;

          a {
            color: #787878;
            padding: 15px 0 15px 7px;
            width: 100%;

            &:before {
              right: 0;
              left: auto;
              color: #787878;
            }
          }

          a[aria-expanded="true"] {
            color: #63B5DC;
            border-bottom: 3px solid #63B5DC;

            &:before {
              color: #63B5DC;
            }
          }
        }
      }

      .panel-body div {
        margin-top: 10px;
        line-height: 25px;
      }
    }

    .tabs {
      padding: 0;
    }
  }
}

@media (max-width: 500px) {
  #product-modal .modal-dialog #thumbnails {
    display: none;
  }

  #product {
    .wrapper {
      padding-top: 10px;
    }

    .product-title-top {
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 10px;
    }

    .product-information .btn.btn-primary.btn-wide {
      font-size: 12px;
      width: 100%;
    }
  }
}

@media (max-width: 450px) {
  #product {
    .expand {
      display: none;
    }

    .current-price {
      font-size: 20px;
      line-height: 32px;
    }

    .customer-addon {
      &.price-right {
        .info-assortment {
          width: 20px;
          height: 20px;
          background-size: 20px 20px;
        }

        .add-to-fav {
          width: 22px;
          height: 20px;
        }
      }
    }
  }
}

@media(max-width: 360px) {
  #product .product-prices .price-tax {
    font-size: 10px;
  }
}

@media (max-width: 350px) {
  .product-information {
    .btn-wide, .btnwide {
      height: auto;
      white-space: normal;
      line-height: 20px !important;
      padding: 10px 25px;
      display: block;
      font-size: 12px;
    }
  }
}
