.customization-modal {
   .modal-content {
     border-radius: 0;
     border: 1px solid $gray-lighter;
     .modal-body {
       padding-top: 0;
       .product-customization-line {
         .label {
           font-weight: bold;
           text-align: right;
         }
         padding-bottom: rem(15px);
         padding-top: rem(15px);
         border-bottom: 1px solid $gray-lighter;
         &:last-child {
           padding-bottom: 0;
           border-bottom: 0;
         }
       }
     }
   }
 }

@include media-breakpoint-down(sm) {
  #accordion {
    .ordered-list, .body-padding {
      padding-left: 0;
    }
  }
}