.search-widget {
  //float: right;
  @include search-box;
}

#content #search_widget {
  button {
    top: 5px;
    right: 6px;
    width: 36px;
    height: 36px;
    i {
      font-size: 31px;
    }
  }
}

.header-top {
  .search-widget {
    form {
      input[type="text"] {
        font-size: 14px;
        min-width: inherit;
        width: 100%;
        line-height: 20px;

        &:focus {
          outline: none;
        }
        input::placeholder {
          font-size: 14px;
        }
      }
    }
  }
}

#checkout {
  #search_widget {
    display: none; /* Not ideal solution by allows to reuse same hooks/templates */
  }
}

#pagenotfound {
  .page-content {
    #search_widget {
      width: 100%;
    }
  }
}

.search-icon {
  background: url(../img/Search.svg) center no-repeat;
  background-size: 15px 15px;
  width: 100%;
  display: block;
  height: 100%;
}

.ordered-articles {
  .search-widget {
    form {
      border: 1px solid #dadada;
      border-radius: 19px;
      margin-bottom: 26px;
    }

    input[type=text] {
      width: 100%;
      line-height: 36px;
      padding: 0 0 0 13px;
      border-radius: 18px;
      margin: 0;
    }
  }
}

#pagenotfound .page-not-found .search-widget input,
#products .page-not-found .search-widget input {
  min-height: 36px;
}

.page-not-found #search-widget.ui-front {
  z-index: 2;
}

#header {
  .header-top {
    .ui-autocomplete {
      a, a:hover {
        color: $prod-title;
      }
    }
  }
}

#search_widget.ui-front {
  z-index: 9;
}

.page-not-found #search_widget.ui-front {
  z-index: 2;
}

.page-not-found {
  &#content {
    #search_widget button {
      top: 4px;
      right: .125rem;
      width: 28px;
      height: 28px;
    }
  }
}

/*** Responsive part ***/
@include media-breakpoint-down(lg) {
  .header-top {
    .search-widget {
      form input[type=text] {
        padding: 0 10px 0 20px;
        line-height: 37px;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .ui-autocomplete {
    max-width: 530px;
  }
}

@include media-breakpoint-down(sm) {
  .header-top {
    .search-widget {
      float: none;
      padding: 0 rem(15px);

      form {
        margin: 0 auto;

        input[type="text"] {
          min-width: inherit;
          background: white;
        }
      }
    }
  }
  .ui-autocomplete {
    right: 15px;
    max-width: calc(100% - 30px);
  }
}