#products,
#pagenotfound {
  #main {
    .page-header {
      margin: rem(32px) 0 rem(48px);
    }
    .page-content {
      margin-bottom: rem(160px);
    }
  }
  .page-not-found {
    background: white;
    padding: rem(16px);
    font-size: $font-size-sm;
    color: $gray-dark;
    box-shadow: none;
    margin: 0;
    max-width: 100%;
    border: 1px solid #dadada;
    border-radius: 10px;
    h4 {
      font-size: $base-font-size;
      font-weight: 700;
      margin: rem(8px) 0 rem(16px);
    }
    .search-widget {
      float: none!important;
      input {
        width: 100%;
        line-height: 36px;
        padding: 0 0px 0 13px;
        border-radius: 18px;
        margin: 0;
      }
      form {
        border: 1px solid #dadada;
        border-radius: 19px;
      }
    }
  }
}
