$distances: '0', '5', '10', '15', '20', '25', '30', '35', '40', '45', '50', '75', '100';
$positions: ('top', 't'), ('right', 'r'), ('bottom', 'b'), ('left', 'l');

$gray-darker: #666;
$gray-dark: #878787;
$gray: #666;
$gray-medium: #e9e9e9;
$grey-medium-light: #dfdfdf;
$grey-light-3: #f2f2f2;
$gray-light: #ebebeb;
$gray-light2: #e6e6e6;
$gray-lighter: #f6f6f6;
$gray-second: #9b9b9b;
$gray-light-second: #dbdbdb;
$gray-lighter-second: #f7f7f7;
$white: #ffffff;
$very-light-grey: #cecece;
$white-smoke: #f1f1f1;
$white: #fff;
$black: #000;

$brand-info: #003B5C;
$brand-primary: #003B5C;
$brand-primary-title: #231f20;
$brand-secondary: #f39d72;
$brand-success: #4cbb6c;
$brand-warning: #ff9a52;
$brand-danger: #ff4c4c;
$menu-border: #2168b6;
$footer-border: #265e8d;
$label-color: $gray-darker;
$prod-title: #000;
$prod-price: $brand-primary;
$lochmara: #19547e;
$alert-success: #3c763d;
$gainsboro: #dadada;
$cms-text: #626367;
$cms-border: #d7d7d7;
$input-border: #bfbfbf;
$btn-primary-color: #fff;
$prod-badge: #00aeef;
$btn-primary-bg: $brand-primary;
$btn-primary-border: transparent;
$btn-secondary-color: $gray-darker;
$btn-secondary-bg: $gray-lighter;
$btn-secondary-border: transparent;
$btn-border-radius: 0;
$headings-font-weight: 700;
$tooltip-bg: $brand-danger;
$border-radius: 0;
$extra-small-space: rem(5px);
$small-space: rem(10px);
$medium-space: rem(20px);
$large-space: rem(30px);
$extra-large-space: rem(40px);
$warning: #FF9A52;
$warning-hover: #FDE7BB;
$alert-warning-bg: rgba(255, 154, 82, 0.3);
$alert-warning-border: $warning;
$alert-warning-text: $gray-darker;
$btn-warning-bg: $warning;
$btn-warning-color: white;
$btn-tertiary-color: #6C868E;
$btn-tertiary-hover: #BBCDD2;
$base-font-size: rem(13px);
$font-size-lg: $base-font-size;
$font-size-sm: $base-font-size;
$font-size-xs: $base-font-size;
$font-size-h1: rem(30px);
$font-size-h2: rem(24px);
$font-size-h3: rem(15px);
$h3-font-size: rem(15px);
$font-size-h4: rem(14px);
$font-size-h5: rem(13px);
$font-size-h6: rem(13px);
$display1-size: rem(50px);
$display1-weight: 600;
$display2-size: rem(35px);
$display2-weight: 400;
$display3-size: rem(25px);
$display3-weight: 400;
$display4-size: rem(20px);
$display4-weight: 400;
$text-muted: $gray;
$cornflower-blue: #9ac3ff;

$mainFontFamily: 'GalaxiePolarisBook';
$headingFontFamily: 'GalaxiePolarisBook';
$mainFontFamily-bold: 'GalaxiePolarisBook';
$mainFontFamily-light: 'GalaxiePolarisBook';
$crd-primary-color: $brand-primary;
$crd-secondary-color: #3cb7e0;
$footer-contact-link: #5fb1ff;
$gray: #666;
$crd-h1: #003B5C;
$crd-grey-category: #595959;

$nav-background-color: $brand-primary;
$nav-links-color: #fff;

$qty-width: 87px;

$light-gray: #A0A0A0;
$dark-gray: #666;
$darkest-gray: #303030;
$border-gray: #D1D1D1;
$background-blue: #D9E4EF;
$accent-green: #14B71A;
$accent-red: #DD0000;
$accent-yellow: #FFCE00;

$cms-content: #666666;

.nice-checkbox {
  input[type="checkbox"] {
    position: absolute;
    left: -9999px;
  }

  .side-label {
    display: inline-block;
    position: relative;
    margin: 0;
    padding-left: 25px;
    cursor: pointer;
  }

  .side-label::before, .side-label::after {
    content: '';
    position: absolute;
    top: 10px;
    left: 0;
  }

  .side-label::before {
    display: block;
    width: 16px;
    height: 16px;
    border: 1px solid #c4c4c4;
    border-radius: 2px;
  }

  .side-label::after {
    display: none;
    width: 16px;
    height: 16px;
    content: '';
    background: url('../img/icons/checkbox-sign.svg') no-repeat center 3px;
  }

  input:checked + .side-label::before {
    border-color: $crd-primary-color;
    background: $crd-primary-color;
  }

  input:checked + .side-label::after {
    display: block;
  }

}

#products .search-string {
  color: $crd-secondary-color;
}
