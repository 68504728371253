#order-confirmation {
  @media(max-width: 1080px) {
    #wrapper {
      padding: 30px 15px;
    }
  }

  .page-header {
    h1 {
      margin-bottom: 10px;
      font-weight: bold;
    }
  }

  #order-items {
    .card-title {
      text-transform: none;
    }
  }

  .order-product-block .display-flex {
    gap: 15px;

    > div:last-child {
      text-align: right;
    }
  }

  #content-hook_order_confirmation {
    margin-bottom: 30px;
    @media(max-width: 1080px) {
      margin-bottom: 0;
    }

    p {
      //margin-left: -15px;
    }
  }


  #order-products td strong {
    word-break: break-word;
  }

  .order-product .break-all {
    word-break: break-word
  }

  #content {
    margin: 0;
    padding: 0;

    #order-items {
      border: none;

      .order-items-padded {
        padding: 25px 35px;
        border-radius: 5px;
        background-color: #ffffff;

        #order-products thead th {
          white-space: nowrap;
        }

        .card-title {
          margin-bottom: 25px;
        }
      }

      @media(max-width: 1080px) {
        padding: 25px 15px;
        .order-items-padded {
          padding: 25px;
        }
      }

      table tr td:last-child {
        text-align: left;
      }
    }

    .order-confirmation-table {
      padding: 10px 20px 60px 20px;

      thead {
        background-color: #F6F6F6;
        color: #A0A0A0;
      }

      .table th {
        border: none;
        font-weight: bold;
      }

      .table td {
        border-top: none;
        border-bottom: 1px solid #dee2e6;
        font-weight: bold;
        word-break: break-word;
      }

      td {
        color: #666666;
      }

      td.price {
        color: $crd-primary-color;
      }
    }
  }

  #order-details {
    @media (max-width: 1024px) {
      padding: 25px 15px;
    }

    .card-title {
      padding: 25px 0;
      text-transform: none;
    }

    ul {
      li {
        font-weight: bold;
        margin-bottom: 15px;
      }
    }

    p {
      margin-top: 15px;
    }

    .invoice-download {
      border-radius: 50px;
      border: solid 1px $crd-primary-color;
      color: $crd-primary-color;
      padding: 15px 20px;
      display: inline-block;
      width: auto;
      margin-top: 15px;
      text-transform: capitalize;
      font-weight: bold;

      .download-icon {
        background: url('../img/icons/download-icon.svg') no-repeat center left;
        color: $crd-primary-color;
        width: 24px;
        height: 16px;
        margin: 0 11px 0 0;
        display: inline-block;
        vertical-align: bottom;
      }
    }
  }

  @media (max-width: 768px) {
    .file-download-btn-cont a {
      min-height: 32px;
      line-height: 32px;
    }
    #order-details {
      .card-title {
        margin-bottom: 0;
      }

      ul li {
        margin-bottom: 0;
      }

      .invoice-download {
        font-size: .7rem;
      }

      .invoice-download {
        margin-left: 10px;
      }
    }
  }
  @media (max-width: 480px) {
    #content.page-order-confirmation {
      margin-left: -15px;
      margin-right: -15px;
    }
    #content #order-items {
      padding: 25px 0;

      .order-items-padded {
        padding: 17px 0 0 0;
      }

      .card-title {
        padding-left: 25px;
      }

      .order-product-block {
        padding: 10px 25px;
      }

      .order-totals-footer .order-total {
        padding: 10px 25px;
      }
    }
    #order-details {
      padding: 0 25px;
    }
    #content {
      .order-confirmation-table {
        padding: 0;

        th, td {
          font-size: 9px;
          padding: 5px;
        }
      }
    }
  }
}

