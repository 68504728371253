/*** SOME GENERIC STYLES ***/
@mixin customer-area-base-box {
  //@include box-shadow;
  background: white;
  padding: rem(16px);
}

@mixin customer-area-box {
  @include customer-area-base-box;
  font-size: $font-size-sm;
  color: $gray-dark;
}

.table-labeled {
  th {
    vertical-align: middle;
  }
  td {
    vertical-align: middle;
  }
  .label {
    font-weight: 400;
    border-radius: 3px;
    font-size: inherit;
    padding: rem(4px) rem(6px);
    margin: rem(2px);
    color: white;
    white-space: nowrap;
  }
}

.page-order {
  .table {
    margin-bottom: 0;
  }
  table {
    th {
      padding: rem(8px);
    }
    td {
      padding: rem(8px);
    }
    thead {
      th {
        text-align: center;
      }
    }
  }
}

#authentication {
  .tooltip.tooltip-bottom {
    padding: 0;
    margin: 0;
  }
  .custom-checkbox {
    display: flex;
    span {
      flex: 0 0 rem(15px);
    }
    label {
      padding-left: rem(10px);
    }
  }
}

#identity,
#authentication {
  .radio-inline {
    padding: 0;
    .custom-radio {
      margin-right: 0;
    }
  }
}

/*** Most of the customer accpunt pages ***/
.page-customer-account {
  #content {
    //@include customer-area-box;
    .order-actions {
      a {
        padding: 0 rem(2px);
      }
    }
    .forgot-password {
      text-align: center;
      font-size: $font-size-sm;
      margin-top: rem(16px);
      padding-bottom: rem(15px);
    }
    .no-account {
      text-align: center;
      font-size: $base-font-size;
    }
  }
  .alert-warning {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.21);
    border-radius: 7px;
    background: #fff;
    border: 1px solid #ebebeb;
    line-height: 50px;
    font-size: 13px;
    color: #222;
    padding: 0 15px;
    text-transform: uppercase;
    font-weight: 700;
  }
  h6 {
    font-weight: 700;
    color: #8b8b8b;
    font-size: 13px;
    line-height: 30px;
  }

  /** TABLES **/
  .order-wrapper {
    border: 1px solid #dadada;
    background: #fff;
    border-radius: 7px;
    padding: 10px 15px;
    color: #8b8b8b;
    .table {
      font-size: 13px;
      font-weight: 700;
      margin: 0;
      .thead-default th {
        text-transform: uppercase;
        color: #222;
        border-bottom-width: 1px;
        background: none;
        border-top: 0;
        padding-bottom: 19px;
        border-color: $gray-light;
      }
      th:first-child, td:first-child {
        padding-left: 0;
      }
      tr:nth-of-type(2n) {
        td:first-of-type {
          //font-weight: normal;
        }
      }
      tr td {
        padding: 15px 0;
      }
      .price {
        font-size: 15px;
      }
      i {
        padding-right: 10px;
        font-size: 17px;
      }
      td, th {
        border-top: 1px solid $gray-light;
      }
      a[data-link-action="view-order-details"] {
        padding: 0 30px;
      }
    }
  }
  /** TABLES **/

}

/*** Login page ***/
.page-authentication {
  #content {
    @include customer-area-base-box;
    max-width: 640px;
    margin: 0 auto;
    &.registration-page {
      max-width: 900px;
    }
  }
  .login-form {
    input {
      &::placeholder {
        color: white;
      }
    }
    .btn {
      margin: 0 15px;
    }
  }
}

/*** Addresses page ***/
.page-addresses {
  .address {
    background: white;
    margin-bottom: 13px;
    border: 1px solid $gray-light;
    border-radius: 5px;
    padding: 31px 31px 20px;
    font-size: $font-size-sm;
    color: $gray-darker;
    .address-body {
      h4 {
        font-size: $font-size-h4;
        font-weight: 700;
        text-transform: uppercase;
        padding-bottom: 14px;
        margin-bottom: 18px;
        padding-right: 75px;
        border-bottom: 1px solid $gray-light;
      }
      address {
        line-height: 24px;
        color: $gray;
        font-weight: bold;
        margin-bottom: 0;
      }
      .address-head {
        position: relative;
      }
      .address-actions {
        position: absolute;
        right: 0;
        top: 0;
        .fa {
          font-size: 18px;
        }
        .fa-pencil-square-o {
          display: inline-block;
          margin-right: 20px;
          top: 1px;
          position: relative;
        }
        a {
          color: black;
        }
      }
    }
    .address-footer {
      border-top: 1px solid $gray;
      padding: rem(8px) rem(16px);
      a {
        margin-right: rem(8px);
        i {
          font-size: $base-font-size;
        }
        span {
          font-size: $font-size-lg;
        }
      }
    }
  }
  .addresses-footer {
    a {
      i {
        font-size: $base-font-size;
      }
      span {
        font-size: $base-font-size;
        margin-top: $small-space;
      }
    }
  }
  .btn {
    padding: 0 18px;
  }
}

#customer-form {
  margin-top: 35px;
}

.addresses-page-description {
  p {
    font-weight: bold;
  }
  line-height: 24px;
  margin-bottom: 26px;
}

/*** Order details page ***/
.page-order-detail {
  font-size: rem(14px);
  color: $gray-dark;
  .box {
    @include customer-area-base-box;
    margin-bottom: rem(16px);
    border-radius: 7px;
    border: 1px solid $gray-lighter;
  }
  h3 {
    font-size: $base-font-size;
    font-weight: 700;
    text-transform: uppercase;
    color: $gray-darker;
    margin-bottom: rem(16px);
  }
  #order-infos {
    ul {
      margin: 0;
    }
  }
  #order-history {
    .history-lines {
      .history-line {
        padding: rem(8px) 0;
        border-bottom: 1px solid $gray-lighter;
        &:last-child {
          border-bottom: 0;
        }
        .label {
          display: inline-block;
          margin: rem(4px) 0;
          padding: rem(4px) rem(6px);
          color: white;
          border-radius: 3px;
        }
      }
    }
  }
  .addresses {
    margin: 0 rem(-15px);
    h4 {
      font-size: $base-font-size;
      font-weight: 700;
    }
  }
  #order-products {
    &.return {
      margin-bottom: rem(16px);
      th {
        &.head-checkbox {
          width: 30px;
        }
      }
      td {
        padding: 1.375rem 0.75rem;
        &.qty {
          min-width: 125px;
          .current {
            width: 30%;
            float: left;
            text-align: right;
            padding-right: 0.5rem;
          }
          .select {
            width: 70%;
            float: left;
            margin: -0.625rem 0;
            padding-left: 0.25rem;
            select {
              text-align: center;
            }
          }
        }
      }
    }
  }
  .order-items {
    padding: 0 !important;
    .order-item {
      padding: rem(16px) rem(16px) 0;
      border-bottom: 1px solid $gray-lighter;
      .checkbox {
        width: 30px;
        float: left;
        padding: 0 rem(15px);
      }
      .content {
        width: calc(100% - 30px);
        float: left;
        padding: 0 rem(15px);
      }
      .desc {
        .name {
          font-weight: bold;
        }
        margin-bottom: rem(16px);
      }
      .qty {
        margin-bottom: rem(16px);
        .q {
          margin-bottom: rem(4px);
        }
        .s {
          margin-bottom: rem(4px);
        }
      }
    }
  }
  .messages {
    .message {
      margin-top: rem(8px);
      border-bottom: 1px solid $gray-lighter;
      &:last-child {
        border-bottom: 0;
      }
      > div {
        margin-bottom: rem(8px);
      }
    }
  }
  .customization {
    margin-top: rem(12px);
  }
}

/*** Order return page ***/
#order-return-infos {
  .thead-default th {
    color: $gray-darker;
  }
  .customization {
    margin-top: rem(12px);
  }
}

/*** My account landing page ***/
.page-my-account {
  #content {
    .links {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      a {
        text-align: center;
        display: inline-block;
        font-size: $base-font-size;
        font-weight: 700;
        text-transform: uppercase;
        color: $gray-dark;
        padding: 0 rem(15px);
        margin-bottom: rem(30px);
        &:hover, &:focus {
          text-decoration: none;
        }
        span.link-item {
          display: block;
          height: 100%;
          @include customer-area-base-box;
        }
        i {
          display: block;
          font-size: 2.6rem;
          width: 100%;
          color: $brand-primary;
          padding-bottom: 3.4rem;
          height: 1em;
        }
        &:hover {
          color: $gray-darker;
        }
      }
    }
  }
}

/*** History page ***/
#history {
  .orders {
    margin: 0 rem(-16px);
    .order {
      a {
        h3 {
          color: $gray-dark;
        }
      }
      padding: rem(12px) rem(16px);
      border-bottom: 1px solid $gray-lighter;
      .label {
        display: inline-block;
        margin: rem(4px) 0;
        padding: rem(4px) rem(6px);
        color: white;
        border-radius: 3px;
      }
      &:last-child {
        border-bottom: 0;
      }
    }
  }
}

/*** FOOTER ***/
.page-footer {
  .account-link {
    margin-right: rem(16px);
    i {
      font-size: $base-font-size;
    }
    span {
      font-size: $font-size-sm;
      vertical-align: middle;
    }
  }
}

.account-left-menu {
  li {
    line-height: 36px;
    font-size: 13px;
    font-weight: 300;
    a {
      color: #8b8b8b;
      position: relative;
      padding-left: 10px;
      &:before {
        content: '\f105';
        position: absolute;
        font-size: 13px;
        font-family: FontAwesome;
        width: 5px;
        left: 0px;
        margin-top: 1px;
      }
      &:hover, &.active {
        color: $crd-secondary-color;
      }
    }
  }
}

.customer-form {
  .margin-right {
    margin-right: 15px;
  }
  .gender {
    width: calc(15% - 15px);
  }
  .title {
    width: calc(15% - 15px);
  }
  .firstname {
    width: calc(35% - 15px);
  }
  .lastname {
    width: 35%;
  }
  .email, .vertreter {
    width: calc(35% - 15px);
  }
  .birthday {
    width: 30%;
    select {
      float: left;
      padding-left: 17px;
      padding-right: 4px;
    }
  }
  .select-day {
    margin-right: 15px;
    width: calc(30% - 15px);
  }
  .select-month {
    margin-right: 15px;
    width: calc(35% - 15px);
  }
  .select-year {
    width: 35%;
  }
  .input-group-btn {
    .btn-primary {
      padding: 0 8px;
      line-height: 34px;
    }
  }
  section {
    padding-bottom: 45px;
    border-bottom: 1px solid $gray-light;
  }
  .custom-checkbox {
    label {
      text-transform: none;
      margin-top: 23px;
      color: $gray-dark;
    }
    input[type=checkbox] + span {
      border: 1px solid $gray-dark;
      padding: 2px 0px 0px 1px;
      margin-top: -4px;
      border-radius: 3px;
      margin-right: 8px;
      .checkbox-checked {
        font-size: 16px;
      }
    }
  }
  .form-control-label {
    text-transform: uppercase;
    margin-top: 10px;
  }
  .firm-info {
    h1 {
      margin-top: 47px;
    }
  }
  .float-md-left {
    float: left;
  }
  .form-control-submit {
    margin-top: 20px;
    min-width: 172px;
  }
  .location > div {
    float: left;
    .form-control {
      padding: 7px 8px;
    }
  }
  .post-code {
    width: calc(30% - 15px);
    margin-right: 15px;
  }
  .city {
    width: calc(35% - 15px);
    margin-right: 15px;
  }
  .country {
    width: 35%;
  }
  .custom-checkbox {
    input[type=checkbox] {
      bottom: 0;
    }
  }
}

.third-margin {
  width: calc(35% - 15px);
}

.third-no-margin {
  width: 30%;
}

/*** Registration page ***/
#register {
  max-width: 850px;
  margin: 0 auto;
  .alert-success {
    p {
      margin: 0;
      color: $alert-success;
    }
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }
  input[type=number] {
    -moz-appearance: textfield;
  }
  .form-footer {
    text-align: center;
    p, p > a {
      font-size: 16px;
      line-height: 28px;
      font-weight: 500;
    }
    p {
      padding-top: 10px;
    }
  }
  #accordion {
    min-height: 250px;
  }
  .collapse {
    min-height: 350px;
  }
  .btn-primary.collapsed {
    background: $gray;
  }
  .btn-primary.open-register {
    margin: 0 20px;
    width: 195px;
    letter-spacing: 1px;
  }
  .customer-form section {
    border-top: 1px solid $gray-light;
  }
  #customer-form {
    margin-top: 0;
  }
  .open-register-buttons {
    margin-top: 25px;
  }
}

.customer-registration {
  .country {
    width: 100%;
  }
  .post-code {
    width: calc(50% - 15px);
    margin-right: 15px;
    float: left;
  }
  .city {
    width: calc(50%);
    float: left;
    margin: 0;
  }
}

select.no-arrow {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: '';
}

.page-header .register-header {
  text-align: center;
  &::after {
    content: none;
  }
}

.align-center {
  text-align: center;
}

#module-crd_registration-registration {
  .register-info p {
    font-size: 16px;
    line-height: 1.5;
  }
}

#password-recovery {
  .btn-primary {
    padding: 0 11px;
  }
}

@include media-breakpoint-down(lg) {
  .page-addresses {
    .address {
      .address-body {
        h4 {
          padding-right: 0;
          margin-bottom: 0;
        }
        .address-actions {
          position: static;
          text-align: right;
          margin: 10px 0;
        }
      }
    }
  }
  .form-control.form-control-select {
    -webkit-appearance: none;
    background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%);
    background-position: calc(100% - 7px) 14px, calc(100% - 2px) 14px;
    background-size: 5px 5px, 5px 5px;
    background-repeat: no-repeat;

    &:focus {
      background-image: linear-gradient(45deg, gray 50%, transparent 50%), linear-gradient(135deg, transparent 50%, gray 50%);
      background-position: calc(100% - 2px) 14px, calc(100% - 7px) 14px;
      background-size: 5px 5px, 5px 5px;
      background-repeat: no-repeat;
      outline: 0;
    }
  }
}

@include media-breakpoint-down(md) {
  .third-margin, .third-no-margin {
    width: 100%;
    margin: 0;
  }
  .customer-form {
    .third-margin, .third-no-margin, .firstname, .lastname {
      width: calc(50% - 15px);
      margin-right: 15px;
    }
    .gender {
      width: calc(20% - 15px);
      margin-right: 15px;
    }
    .title {
      width: calc(30% - 15px);
      margin-right: 30px;
    }
    .birthday {
      width: calc(50% - 15px);
    }
  }
  .page-customer-account .order-wrapper .table {
    td.order-status {
      border-top: none;
    }
    a.btn-primary {
      padding: 0 11px;
    }
  }
  #history.page-customer-account h6 {
    line-height: 19px;
  }
}

@include media-breakpoint-down(sm) {
  .customer-form {
    .birthday {
      width: 100%;
    }
    .firstname, .lastname {
      width: 100%;
      margin: 0;
    }
    .third-margin, .third-no-margin {
      width: 100%;
      margin: 0;
    }
  }
  #history .orders {
    margin: 0;
    .order {
      line-height: 20px;
    }
    .order-actions {
      height: 83px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .fa {
      font-size: 19px;
      margin: 7px 0;
    }
  }
  .page-authentication {
    #content {
      padding: 0;
    }
    .login-form .btn {
      margin: 10px 11px;
    }
  }
}

@media (max-width: 767px) {
  .customer-form {
    .gender, .title {
      width: 100%;
    }
  }
  #register {
    .btn-primary.open-register {
      margin: 10px auto;
    }
    #accordion {
      min-height: 0;
    }
    .open-register-buttons {
      margin-top: 15px;
    }
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .customer-form .birthday select {
    padding-left: 11px;
  }
}

//account header pop-up style
.info-popup {
  position: relative;

  .info-popup-content {
    display: none;
    padding: 20px;
    border-radius: 20px;
    color: #000;
    box-shadow: 0 0 4px #ccc;
    top: 47px;
    left: -195px;
    border: 1px solid #ccc;
    position: absolute;
    z-index: 9999;
    max-width: 360px;
    background: #fff;
  }

  .fa {
    position: absolute;
    left: -24px;
    font-size: 22px;
    cursor: pointer;
    color: $crd-primary-color;

    display: inline-block;
    -moz-animation: pulse 2s infinite linear;
    -o-animation: pulse 2s infinite linear;
    -webkit-animation: pulse 2s infinite linear;
    animation: pulse 2s infinite linear;

  }

  .info-popup-text {
    margin-bottom: 13px;
  }

  .tooltip-arrow {
    width: 70px;
    height: 60px;
    overflow: hidden;
    position: absolute;
    left: 50%;
    margin-left: -35px;
    top: -26px;

    &:after {
      content: "";
      position: absolute;
      left: 20px;
      bottom: 15px;
      width: 25px;
      height: 25px;
      box-shadow: -5px -5px 5px -4px #ccc;
      transform: rotate(45deg);
      background: white;
      border: 2px solid white;
    }
  }

  .hide-all-popups, .reset-all-popups {
    border: none;
    background: none;
    color: $crd-primary-color;
    margin-top: 10px;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

#search-popup {
  .fa {
    top: 14px;
    color: white;
  }

  .info-popup-content {
    top: 53px;
  }
}

#filters-popup {
  position: absolute;
  left: -18px;
  top: 0;
  z-index: 2;

  .info-popup-content {
    width: 270px;
    left: -150px;
  }
}

#account-popup {
  .info-popup-content {
    width: 270px;
    left: -148px;
    text-align: left;
    white-space: normal;
  }
}
