#history {
  #main {
    .order-wrapper {
      padding: 0;
      border: none;
    }

    .table-labeled {
      color: #666666;

      thead {
        background-color: #F6F6F6;
        line-height: 1.5;

        th {
          border: none;
          color: #A0A0A0;
          padding-left: 0;
        }

        .order-reference-heading {
          padding-left: 10px;
        }
      }

      tbody, tfoot {
        tr {
          border-top: solid 1px #E9E9E9;
          line-height: 1.5;

          td {
            border: none;
            font-weight: bold;
          }

          td.price {
            color: #2A527E;
          }
        }
      }

      .btn-primary.history-btn {
        opacity: 0;
        text-transform: capitalize;
        line-height: 28px !important;
        height: 32px;
      }

      .history-row {
        &:hover {
          background-color: #F6F6F6;

          .history-btn {
            opacity: 1;
          }
        }
      }
    }

    .order-history-sorting .dateFilter {
      width: 100%;
      @media (max-width: 768px) {
        margin-right: 0;
        padding-right: 0;
      }

      label {
        color: #a0a0a0;
        text-transform: capitalize;
      }

      input {
        padding: 0 15px;
        max-width: 100%;
        color: #666666;
        line-height: 50px;
      }
    }

    .search-error-container {
      margin: 150px auto;
      padding: 0 5%;
      @media (max-width: 767px) {
        margin: 30px 0;
        padding: 0;
      }

      .icon-holder {
        text-align: center;

        .search-icon {
          height: 46px;
          width: 46px;
          background: url('../img/icons/search-icon-new.svg') no-repeat center left;
          display: inline-block;
          margin: 0 6px;
        }
      }

      p {
        font-size: 16px;
        line-height: 32px;
        color: #666666;
        text-transform: none;
        width: 70%;
        text-align: center;
        margin: 30px auto;
        @media (max-width: 767px) {
          padding: 0;
          width: 100%;
        }
      }
    }
  }

  .order-wrapper .table th:first-child, .order-wrapper .table td:first-child {
    padding-left: 10px;
  }
}

@media (max-width: 767px) {
  .page-customer-account #wrapper > .container > .row,
  #module-crd_favorites-productlist #wrapper > .container > .row,
  #module-crd_history-historyproducts #wrapper > .container > .row,
  #history #wrapper > .container > .row,
  #module-crd_outstandinginvoice-outstanding #wrapper > .container > .row
  #module-crd_orderhistory-orderdetails #wrapper > .container > .row {
    margin-left: 0;
    margin-right: 0;

    #left-column,
    #content-wrapper {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

#order-details-page, #order-confirmation {
  .detail-wrapper {
    background-color: #ffffff;
    padding: 35px;
    border-radius: 5px;

    .page-header {
      h3 {
        font-size: 21px;
        margin-bottom: 15px;
        @media (max-width: 767px) {
          font-size: 18px;
        }
      }

      h5 {
        font-weight: normal;
        line-height: 20px;
        margin-bottom: 0;
      }

      h4 {
        font-size: 18px;
        margin-bottom: 20px;
        margin-top: 20px;
        @media (max-width: 767px) {
          font-size: 14px;
          margin-bottom: 0;
        }
      }
    }

    .addresses {
      margin-bottom: 40px;
      @media (max-width: 767px) {
        margin: 0;
        padding: 15px;
        border-bottom: 1px solid $border-gray;
        address {
          margin-bottom: 0;
        }
        #invoice-address {
          margin-top: 15px;
        }
      }

      h4 {
        font-size: 16px;
      }
    }
  }

  #order-products {
    color: #666666;

    thead {
      background-color: #F6F6F6;
      color: $darkest-gray;
      line-height: 1.5;

      th {
        border: none;
      }
    }

    tbody, tfoot {
      tr {
        border-top: solid 1px #E9E9E9;
        line-height: 1.5;

        td {
          border: none;
          font-weight: bold;
        }

        td.price {
          color: #2A527E;
        }
      }
    }
  }
}

.page-customer-account .order-wrapper .table .thead-default th {
  line-height: 50px;
  padding: 0 10px;
}
