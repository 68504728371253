#block-reassurance{
  margin-top: rem(32px);
  img{
    opacity: .7;
    width: rem(25px);
    margin-right: rem(10px);
  }
  li{
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    &:last-child {
      border: 0;
    }
  }
  li .block-reassurance-item{
    padding: 1rem 1.5rem;
  }
  #product &{
    @include box-shadow;
    background: white;
    span{
      font-weight: 700;
    }
  }
}
