#wrapper {
  &:has(.crd-form-wrapper) {
    background: #fff;
  }

  .crd-form-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 88px 28px;

    .hbspt-form {
      .hs-form-field {
        > label {
          display: none;
        }
      }

      input:not([type='checkbox']) {
        background-color: rgb(247, 247, 247);
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border: none;
        border-bottom: 1px solid rgb(112, 111, 111);
        margin-top: 24px;
        height: 54px;
        font-family: "Futura Light", sans-serif;
        color: rgb(112, 111, 111);
        font-size: 17px;
        letter-spacing: .5px;
        padding-left: 15px;
        padding-top: 30px;

        &:hover, &:focus {
          box-shadow: none;
          outline: none;
          border: none;
        }
      }
    }
  }

  .hbspt-form {
    font-family: "GalaxiePolaris-Book", sans-serif;

    .input {
      margin-right: 0;
    }

    .hs-input:not([type='checkbox']) {
      width: 100%;
    }

    fieldset, .hs-dependent-field {
      max-width: none;

      > div {
        position: relative;
        margin-bottom: 20px;
      }

      &.form-columns-2 {
        display: flex;
        justify-content: space-between;

        .hs-form-field {
          width: 49%;
        }
      }
    }

    .hs_institution_document {
      input {
        height: auto;
        background: #fff;
        padding: 5px 10px;
      }
    }

    input:not([type='checkbox']), select, textarea {
      height: 100%;
      min-height: 50px;
      border: 2px solid #d1d1d1;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 0 10px;
      line-height: 50px;
      transition: all .35s ease;

      &:hover, &:focus, &:focus-within {
        border: 2px solid #d1d1d1;
        box-shadow: 0 0 5px #003b5c;
        outline: none;
      }

      &.hs-button {
        min-width: 172px;
        min-height: 0;
        margin: 20px 0;
        height: 44px;
        line-height: 25px;
        font-size: 17px;
        color: #fff;
        padding: 6px 20px;
        border: none;
        border-radius: 22px;
        background-color: #00385c;
        text-align: center;
        box-shadow: 0 25px 25px -17px #595959;

        &:hover {
          background-color: #00afd7;
        }
      }

      &[type='file'] {
        padding-left: 0;

        &::file-selector-button {
          border: none;
          background-color: #00385c;
          transition: all .35s ease-in-out;
          color: #fff;
          text-transform: uppercase;
          font-size: 14px;
          height: 100%;
        }

        &:hover {
          &::file-selector-button {
            background-color: #00afd7;
          }
        }
      }
    }

    textarea {
      height: 100px;
    }

    label, legend {
      font-size: 12px;
      text-transform: none;
      color: #666;
      line-height: 18px;
      font-weight: 700;
    }

    .hs-error-msgs {
      margin-top: 5px;
      position: absolute;
      top: 100%;
      left: 20px;
      z-index: 9;

      label {
        color: gray;
        padding: 10px;
        display: inline-block;
        position: relative;
        background: #fff;
        border: 1px solid #000;
        box-shadow: 3px 5px 5px 0px rgba(0,0,0,.15);
        border-radius: 4px;

        &:after, &:before {
          bottom: 100%;
          left: 20px;
          border: solid transparent;
          content: "";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
        }

        &:after {
          border-color: rgba(255, 255, 255, 0);
          border-bottom-color: #fff;
          border-width: 5px;
          margin-left: -5px;
        }
        &:before {
          border-color: rgba(0, 0, 0, 0);
          border-bottom-color: #000;
          border-width: 6px;
          margin-left: -6px;
        }
      }
    }

    .hs-field-desc {
      font-weight: normal;
      font-style: italic;
    }

    .hs-form-booleancheckbox-display {
      display: flex;
      align-items: center;
      margin-top: 40px;

      span {
        margin-left: 10px;
      }
    }

    .hs-fieldtype-text,
    .hs-fieldtype-select {
      margin-bottom: 20px;
      position: relative;
    }

    .hs_error_rollup {
      display: none;
    }

    > .submitted-message > p {
      font-size: 15px;
      font-family: GalaxiePolarisBook, sans-serif;
      line-height: 22px;
      color: rgb(89, 89, 89);
    }

    fieldset ~ .hs_submit {
      text-align: center;
    }
  }
}

