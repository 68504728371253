.new-btn-primary, header a.new-btn-primary {
    min-width: 138px;
    max-width: 350px;
    height: 44px;
    line-height: 25px;
    font-size: 17px;
    color: #ffffff !important;
    padding: 6px 20px;
    border: none;
    border-radius: 22px;
    background-color: #00385c;
    font-family: "GalaxiePolaris-Book", sans-serif;
    text-align: center;
    box-shadow: 0px 25px 25px -17px #595959;

    &:hover{
        background-color: #00afd7;
        color: #ffffff;
    }

    &.ui-corner-all{
        border-radius: 22px !important;
    }

    #related & {
        height: 39px;
    }

    #history &{
        width: 170px;
    }

    &:disabled{
        color: #8ca7b5;
        cursor: not-allowed;
    }

    &.apply-filter{
        height: 44px !important;
    }

    &.history-btn{
        opacity: 0;
    }

    @media screen and (max-width:400px){
        &.reorder-btn{
            font-size: 12px;
            padding: 3px 10px;
        }
    }

    @media screen and (max-width:1080px){
        min-width: 120px;
        &.cart-button{
            font-size: 12px;
            border-radius: 0px;
            width: 100%;
            max-width: none;
        }
    }
}
.download-pdf-link.new-btn-primary{
    max-width: 450px
}

.sign-out,.my-account{
    .new-btn-primary{
        @media screen and (max-width:1080px){
            margin: auto;
            max-width: 200px;
        }
    }
}

div, a{
    &.new-btn-primary{
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.new-btn-secondary {
    min-width: 138px;
    max-width: max-content;
    height: 44px;
    line-height: 25px;
    font-size: 17px;
    color: #003b5c !important;
    padding: 6px 20px;
    border: none;
    border-radius: 23px !important;
    background-color: rgba(255,255,255, 0.99);
    font-family: "GalaxiePolaris-Book", sans-serif;
    box-shadow: 4px 4px 13px rgba(89,89,89, 0.2);
    text-align: center;
    white-space: nowrap;

    &:hover {
         color: #003b5c;
         background-color: rgba(247,247,247, 0.5);
         font-family: "GalaxiePolaris-Book", sans-serif;
         box-shadow: 5px 5px 18px rgba(53,53,53, 0.38), 1px 1px 5px rgba(0,0,0, 0.1);
    }

    &.cart-button {
        font-size: 14px;
    }

    @media screen and (max-width:400px) {
        &:not(#create-account){
            padding: 6px 0;
        }
    }

    @media screen and (max-width:1024px){
        &.cart-button {
            font-size: 12px;
            border-radius: 0px;
            width: 100%;
            max-width: none;
        }
        min-width: 120px;
    }

}

a.new-btn-secondary{
    display: flex;
    align-items: center;
    justify-content: center;
}

.new-btn-container{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.new-big-btn{
    max-width: none;
    width: fit-content;
    height: auto;
    padding: 20px 30px;
    border-radius: 70px;
    margin: 20px;
    min-width: 300px;
    min-height: 60px;
}

.fill-width{
    width:100%;
}

.btn-min-width{
    width: 138px;
}
