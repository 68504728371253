.mm-page.crd-gray {
  background-color: #f6f6f6;
}

#promoted-products-list {
  opacity: 0;
  visibility: hidden;
  transition: opacity 1s ease;
  -webkit-transition: opacity 1s ease;

  &.slick-initialized {
    visibility: visible;
    opacity: 1;
  }

  .slick-slide {
    margin: 0 20px;
  }

  .slick-list {
    margin: 0 -20px;
    padding: 28px 0 40px;
    height: 100% !important; // slick slider adds a height on mobile that cuts the bottom part of the cards
  }

  .slick-dots {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style-type: none;
    bottom: -10px;
    @media (max-width: 811px) {
      bottom: -24px;
    }
  }

  //.slick-arrow {
  //  display: none !important;
  //}

  .slick-dots li {
    margin: 0 5px;
    width: 10px;
    height: 10px;
  }

  .slick-dots li button {
    display: block;
    width: 8px;
    height: 8px;
    padding: 0;
    border: none;
    border-radius: 100%;
    background-color: #c4d0d7;
    text-indent: -9999px;
  }

  .slick-dots li.slick-active button {
    background-color: #185381;
  }

  .promoted-miniature {
    position: relative;
    overflow: hidden;
    border-radius: 3px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border: none;
    transition: box-shadow .35s ease;

    &:hover {
      overflow: auto;
      box-shadow: 4px 4px 20px rgba(168, 168, 168, 0.7);
    }

    &.product-miniature {
      .product-title {
        max-width: 80%;
      }

      .product-title a {
        color: #185381;
        font-family: 'NeoSansProMedium';
        font-weight: 500;
        font-size: 20px;
        line-height: 22px;
        -webkit-line-clamp: 3;
        max-height: 66px;
      }

      .manufacturer_name > div {
        background: none;
        color: #595959;
        font-family: 'NeoSansProMedium';
        font-size: 14px;
        text-transform: none;
        padding: 0;
      }

      .regular-price::before {
        right: -6%;
        left: auto;
      }
    }

    .thumbnail-container {
      min-height: 320px;
      padding: 40px;
      border-radius: 0;
      border-color: transparent;
      box-shadow: 0 2px 5px #d1d1d1;
      border-bottom: 4px solid rgb(0, 175, 215);

      img {
        max-width: 100%;
        height: auto;
      }
      .product-details-container {
        padding-right: 0;
      }
    }

    .product-image > div {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      height: 100%
    }

    .add-cart-redesign {
      margin-top: 20px;
      max-width: 80%;
    }

    .product-title {
      margin-top: 8px;
    }

    .product-price-and-shipping {
      display: inline-block;

      .product-price-and-shipping-inner {
        display: flex;
        flex-direction: column;
        text-align: right;
      }

      span.regular-price {
        text-decoration: none;
      }

      span.price {
        font-size: 16px;
        color: #3cb7e0;
        font-family: 'NeoSansProMedium';
      }

      span {
        font-size: 12px;
      }
    }

    .product-availability {
      left: 15px;
      top: 15px;
      right: auto;
    }

    .product-reference {
      font-size: 10px;
      margin-top: 5px;
      color: #595959;
    }

    .add-to-fav {
      top: 40px;
      right: 40px;
      left: auto;
    }

    .add-cart-redesign {
      margin-left: 20%;

      .qty-box .qty-flex-container {
        .update-box {
          position: inherit;
          width: auto;
          max-width: inherit;

          .cart-button {
            position: inherit;
            top: auto;
          }
        }

        .input-box {
          width: calc(45% - 5px);
          max-width: calc(45% - 5px);
          input {
            color: #706F6F;
            font-family: 'NeoSansProMedium';
            font-weight: normal;
            font-size: 16px;
            line-height: 30px;
          }

          .value-button.decrease,
          .value-button.increase {
            background: none;
          }

          svg path {
            fill: #706F6F;
          }
        }
      }
    }
  }

  .flex-column-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%
  }
}

.promoted-title {
  font-family: 'NeoSansProStd';
  font-size: 26px;
  color: #3cb7e0;
  font-weight: normal;
}
@media (max-width: 1024px) {
  .category-title-top {
    margin-bottom: 40px;
  }
  #promoted-products-list {
    .promoted-miniature {
      &.product-miniature {
        .new-btn-primary {
          min-width: 44px;
          border-radius: 50%;
          padding: 0;

          &:hover svg {
            fill: $crd-secondary-color;
          }
        }

        .qty-box {
          .qty-flex-container {
            justify-content: flex-end;
          }
        }
        .add-cart-redesign {
          max-width: 100%;
          margin-left: 0;
        }
      }
    }
  }
}

@media (max-width: 821px) {
  #promoted-products-list {
    .slick-slide {
      margin: 0 12px;
    }
    .slick-list {
      margin: 0 -12px;
    }
    .promoted-miniature {
      &.product-miniature {
        .thumbnail-container {
          padding: 20px;
        }
        .add-to-fav {
          top: 20px;
          right: 20px;
        }
        .product-title {
          max-width: 100%;
        }
        .product-title a {
          font-size: 16px;
        }
        .add-cart-redesign {
          max-width: 100%;
          margin-left: 0;
          .qty-box .qty-flex-container .input-box {
            width: calc(50% - 5px);
            max-width: calc(50% - 5px);
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  #promoted-products-list {
    .slick-slide {
      margin: 0;
    }
    .slick-list {
      margin: 0;
      padding-bottom: 0;
    }
    .slick-dots {
      bottom: -50px;
    }
    .promoted-miniature {
      &.product-miniature {
        .manufacturer_name > div {
          font-size: 12px;
        }
        .product-title {
          max-width: 80%;
        }
        .product-title a {
          font-size: 16px;
        }
        .product-reference {
          text-align: right;
          margin-top: 0;
        }
        .product-price-and-shipping {
          .product-price-and-shipping-inner {
            text-align: left;
          }
          span.price {
            font-size: 14px;
            color: $crd-secondary-color;
          }
        }
        .regular-price::before {
          left: -6%;
          right: auto;
        }

        .add-to-fav {
          top: 20px;
          right: 20px;
        }
      }

      .thumbnail-container {
        min-height: 460px;
        padding: 20px 10px;
        img {
          display: inline-block;
          padding: 10px 0;
        }

        .product-details-container {
          padding: 0;
        }
      }

      .add-cart-redesign {
        max-width: 100%;
        padding-right: 10px;
      }
    }
  }
}
