.contact-rich {
  color: $gray-dark;
  margin-bottom: rem(32px);

  h4 {
    text-transform: uppercase;
    color: $gray-darker;
    margin-bottom: rem(32px);
    position: relative;
    padding-bottom: 12px;
    font-family: $headingFontFamily;

    &:after {
      content: "";
      width: 50px;
      border-bottom: 1px solid #e6e6e6;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  .block {
    height: auto;
    overflow: hidden;
    display: flex;
    align-items: center;
    padding-bottom: 45px;

    .icon {
      float: left;
      width: 36px;

      i {
        font-size: 26px;
      }

      .fa-phone {
        font-size: 29px;
      }

      .fa-map-marker {
        font-size: 31px;
      }
    }

    .data {
      color: $gray;
      font-size: $font-size-sm;
      width: calc(100% - 36px);
      overflow: hidden;
      line-height: 22px;
    }

    a {
      line-height: 18px;
    }

    .fa {
      color: $crd-primary-color;
    }
  }
}

.contact-form {
  background: white;
  padding: rem(16px);
  padding-top: 0;
  color: $gray-dark;
  width: 100%;

  h3 {
    text-transform: uppercase;
    color: $crd-primary-color;
    font-family: $headingFontFamily;
    position: relative;
    padding-bottom: 12px;

    &:after {
      content: "";
      width: 50px;
      border-bottom: 1px solid #e6e6e6;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  .input-group .form-control:active,
  .input-group .form-control:focus,
  .input-group .form-control:hover {
    z-index: inherit;
  }

  .required {
    color: $crd-primary-color;
  }

  .form-group {
    margin-bottom: 20px;

    input::placeholder {
      font-weight: normal;
    }
  }

  .form-footer {
    margin-top: 30px;
  }

  .btn-primary {
    padding: 0 20px;
  }

  ul {
    margin-bottom: 0;
  }

  .input-group {
    input {
      border-right: none;
    }

    .btn-default {
      padding: 16px 16px 14px;
      border-radius: 4px;
      border: 1px solid $input-border;
      margin-left: -4px;
      font-weight: bold;
    }
  }

  textarea.form-control {
    height: inherit;
  }

  .contact-captcha {
    float: left;
  }
}

@include media-breakpoint-down(sm) {
  .footer-container .links ul.footer-border > li {
    background: none;
    border-bottom: 0;

    a {
      color: #9ac3ff;
      font-weight: normal;
    }

    &:after {
      display: none;
    }
  }
  .contact-form {
    .contact-captcha {
      float: none;
    }
  }
}